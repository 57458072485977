import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { ReactComponent as AddCircleIcon } from '../../../images/Shared/Add_circle.svg'; 

interface AddButtonProps {
  label: string;
}

const AddButtonContainer = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#00726B',
  border: '1px solid #e0e0e0',
  width: '170px',
  height: '48px',
  borderRadius: '5px',
  padding: '8px, 16px, 8px, 16px',
  cursor: 'pointer',
  marginLeft: '16px', 
});

const AddButtonLabel = styled(Box)({
  fontSize:14,
  fontWeight:500,
  fontFamily: 'Rubik, sans-serif', 
  marginLeft: '7px',
  color: '#fff', 
});

const AddButton: React.FC<AddButtonProps> = ({ label }) => {
  return (
    <AddButtonContainer>
      <AddCircleIcon />
      <AddButtonLabel>{label}</AddButtonLabel>
    </AddButtonContainer>
  );
};

export default AddButton;
