/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './BatchesForm.module.css';
  import RadioButton from '../../components/Batches/RadioButton';
 import Counter from '../../components/Shared/Textfields/CounterShort';
import { BatchesService } from '../../services/BatchesService';
import { AccessCodeBatchDTO, BatchDTO, BatchFormDTO, CreateBatchDTO, UpdateBatchDTO } from '../../dto/BatchesDTO';
 import DropdownSelector from '../../components/Shared/Textfields/DropdownSelector';
import { PublishersService } from '../../services/PublishersService';
import {BooksDTO, BooksResponseDTO, Option } from '../../dto/BooksDTO';
import { BooksService } from '../../services/BooksService';
import { SchoolsService } from '../../services/SchoolsService';
import { SchoolsDTO } from '../../dto/SchoolsDTO';
import { PublisherResponseDTO, PublishersDTO } from '../../dto/PublishersDTO';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { GenerateAccessCodeDTO } from '../../dto/DashboardDTO';
import { CircularProgress } from '@mui/material';
import { GradesDTO } from '../../dto/GradesDTO';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { UserContext } from '../../UserContext';

interface PublisherFormProps {
  mode: 'add' | 'edit' | 'view';
}


const BatchesForm: React.FC<PublisherFormProps> = ({ mode }) => {
  const {user}=useContext(UserContext)
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [batchData, setBatchData] = useState<BatchFormDTO|null>(null);
  const [selectedValue, setSelectedValue] = useState<string>("on use");


  const [publisherOptions,setPublisherOptions]=useState<{label:string,value:string}[]>([]);
  const [booksOptions, setBooksOptions] = useState<{label:string,value:string}[]>([]);
  const [schoolsOptions, setSchoolsOptions] = useState<{label:string,value:string}[]>([]);


  const [selectedPublisher, setSelectedPublisher] = useState<Option|null>(null);
  const [selectedBook, setSelectedBook] = useState<Option|null>(null);
  const [selectedSchools, setSelectedSchools] = useState<Option|null>(null);


  const [expirationDay, setExpirationDays] = useState<number | undefined>(undefined);
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);

  const [loadingBatch,setLoadingBatch]=useState(true)
  const [loadingPublisher,setLoadingPublisher]=useState(true)
  const [loadingBooks,setLoadingBooks]=useState(true)
  const [loadingSchools,setLoadingSchools]=useState(true)

  const [buttonLoading,setButtonLoading]=useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false); 
  const [modalMode, setModalMode] = useState<'archive' | 'delete'|'activate'>('archive');

  const handleDelete = () => {
    setModalMode('delete');
    setModalOpen(true);
  };

  const handleArchive = () => {
    setModalMode('archive');
    setModalOpen(true);
  };

  const handleActivate = () => {
    setModalMode('activate');
    setModalOpen(true);
  };

  const handleConfirm = async () => {
   
    if (modalMode === 'delete' && batchData?._id ) {
      try {
       const message= await BatchesService.deleteBatch( batchData._id);
        showSuccessToast(message);
        navigate('/batches');
      } catch (error) {
        console.error('Error deleting batch:', error);
        showErrorToast('Error deleting batch.');
      }
    } else if (modalMode === 'archive' && batchData?._id) {
      try {
        const message=await BatchesService.toggleArchive( batchData._id);
        showSuccessToast(message);
        navigate('/batches');
      } catch (error) {
        console.error('Error archiving batch:', error);
        showErrorToast('Error toggling archive batch.');
      }
    }
    else if (modalMode === 'activate' && batchData?._id) {
      try {
        const message=await BatchesService.toggleActivate( batchData._id);
        showSuccessToast(message);
        navigate('/batches');
      } catch (error) {
        console.error('Error activating batch:', error);
        showErrorToast('Error toggling activate batch.');
      }
    }
    setModalOpen(false); 
  };

  //fetch batch
  useEffect(() => {
    const fetchBatch = async () => {
      try {
         if (id) {
          const fetchedData:BatchDTO = await BatchesService.getBatchById( id);
          setBatchData({
            _id:fetchedData._id,
            Title: fetchedData.title ?? "", 
            Publisher: fetchedData.book.publisher?.fullname|| "", 
            Book: fetchedData.book?.bookTitle ||"", 
            School: fetchedData.school?.name ||"",
            LicenceType: fetchedData.licenseType ?? "fixed", 
            NumberOfCodeNeed:'',
            CodeLength:undefined,
            CodeStart: "", 
            CodeEnd: "",
            CharacterList: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
            StartDate: fetchedData.dateCreated ?? "", 
            EndDate:undefined,
            Archived:fetchedData.archived,
            Active:fetchedData.active
          });

          setSelectedPublisher({
            label: fetchedData.book.publisher?.fullname || "",
            value: fetchedData.book.publisher?._id || "",
          });
          
          setSelectedBook({
            label: `${fetchedData.book?.bookTitle} | ${fetchedData.book?.grades.map(grade => grade.engName).join(' - ')} | ${fetchedData.book?.category.engName}`,
            value: fetchedData.book?._id || "",
          });
  
          setSelectedSchools({
            label: fetchedData.school?.name || "",
            value: fetchedData.school?._id || "",
          });

          setSelectedValue(fetchedData.licenseType)
        }else{
          showErrorToast("Error batch id not found.")
        }
      } catch (error) {
        showErrorToast('Error fetching batch')
        console.error('Error fetching publisher:', error);
      }finally{
        setLoadingBatch(false)
      }
    };

    if (id) {
      fetchBatch();
    }else{
      setBatchData({        
        CharacterList: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      });
      setLoadingBatch(false)
    }
  }, [mode, id]);

 //fetch publishers
  useEffect(() => {
  const fetchPublishers = async () => {
    try {
      
      const response:PublisherResponseDTO = await PublishersService.getPublishers(false,10000,1,"");
      const options = response.publishers.map((publisher: PublishersDTO) => ({
        label: publisher.fullname,
        value: publisher._id,
      }));
      setPublisherOptions(options);
      
    } catch (error) {
      showErrorToast('Error fetching publishers')

      console.error('Error fetching publishers:', error);
    }finally{
      setLoadingPublisher(false)
    }
  };

  fetchPublishers();
  }, []);

  //fetch books
  useEffect(() => {
    const fetchBooks = async () => {
      try {
   
        const response:BooksDTO[] = await BooksService.getBooksByPublisher(10000,1,false,true,selectedPublisher?.value!);
        const options = response.map((book: BooksDTO) => ({
          label: `${book.bookTitle} | ${book.grades?.map((grade: GradesDTO) => grade.engName).join(' - ')} | ${book.category?.engName || ""}`,
          value: book._id,
        }))
        setBooksOptions(options);
      
      } catch (error) {
        showErrorToast('Error fetching books')

        console.error('Error fetching books:', error);
      }finally{
        setLoadingBooks(false)
      }
    };
  
    fetchBooks();
  }, [selectedPublisher]);
  
  //fetch schools
  useEffect(() => {
    const fetchPublishers = async () => {
      try {
   
          const response = await SchoolsService.getSchools(false,1,10000,"");
          const options = response.schools.map((publisher: SchoolsDTO) => ({
            label: publisher.name,
            value: publisher._id,
          }));
          setSchoolsOptions(options);
     
      } catch (error) {
        showErrorToast('Error fetching schools')

        console.error('Error fetching publishers:', error);
      }finally{
        setLoadingSchools(false)
      }
    };
  
    fetchPublishers();
  }, []);

  const handlePublisherChange = (selected: Option|Option[]) => {
    const selectedOption=selected as Option;
    setSelectedBook(null)
    setSelectedPublisher(selectedOption);
    setBatchData({ ...batchData, Publisher: selectedOption.value || '',Book:'' });
  };

  const handleBookChange = (selected: Option|Option[]) => {
    const selectedOption=selected as Option;
    setSelectedBook(selectedOption);
    setBatchData({ ...batchData, Book: selectedOption.value });
  };

  const handleSchoolsChange = (selected: Option|Option[]) => {
    const selectedOption=selected as Option;
    setSelectedSchools(selectedOption);
    setBatchData({ ...batchData, School: selectedOption.value });
  };

  const handleTypeChange = (selected: string) => {
    setSelectedValue(selected);
    setBatchData({ ...batchData, LicenceType: selected });
    
    if (selected === 'on use') {
        setExpirationDays(0); 
        setExpiryDate(undefined);
    } else if (selected === 'fixed') {
        setExpirationDays(undefined);
        setExpiryDate(""); 
    }
  };

  const handleNumberOfCode =(e:React.ChangeEvent<HTMLInputElement>)=>{
    const value=e.target.value
    if (value === "" || !isNaN(Number(value))) {
      setBatchData({ ...batchData, NumberOfCodeNeed: value });
    }  
  }

  const handleCodeLength =(e:React.ChangeEvent<HTMLInputElement>)=>{
    const value=e.target.value
    if (value === "" || !isNaN(Number(value))) {
      setBatchData({ ...batchData, CodeLength: value });
    }  
  }
  
  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(mode==='add'&&!validateForm()){
      showErrorToast("All fields are required")
      return
    }
    setButtonLoading(true)
    if (mode === 'add') {
      await handleAdd()
      navigate('/batches');
    } else if (mode === 'edit') {
      await handleUpdate()
      navigate(`/batches/view/${id}`);
    }
  };

  const handleAdd=async()=>{
    try {
      if(!batchData){
        throw new Error('Batch data is required');
      }
  
      const createBatchData: CreateBatchDTO = {
        batchData: {
          title: batchData.Title,
          book: batchData.Book!,
          school: batchData.School!,
          licenseType: selectedValue!,
          expirationDays: batchData.LicenceType === 'on use' ? expirationDay : undefined,
          accessCodes: [],
        },
        numCodes: parseInt(batchData.NumberOfCodeNeed!),  
        charList: batchData.CharacterList!,
        prefix: batchData.CodeStart!,
        suffix: batchData.CodeEnd!,
        expiryDate:batchData.LicenceType === 'fixed'? expiryDate : undefined,
      };
        await BatchesService.addBatch( createBatchData);
        showSuccessToast('Batch Created!')
    } catch (error) {
      console.error('error while adding a batch: ',error) 
      showErrorToast('Error creating batch.')
    }finally{
      setButtonLoading(false)
    }
    
  }

  const handleUpdate = async () => {
    try {
      if (!batchData || !selectedBook || !selectedSchools) {
        throw new Error('Batch data, book, and school are required');
      }

      const updateBatchData: UpdateBatchDTO = {
        title: batchData.Title!,
        book: selectedBook.value,  
        school: selectedSchools.value,  
        licenseType: batchData.LicenceType!,
        accessCodes: [], 
      };

      //generate access codes
      if(batchData.CodeStart && batchData.CodeEnd && batchData.NumberOfCodeNeed && batchData.CodeLength && batchData.CharacterList ){
        console.log('noooo  ')
        if(batchData.LicenceType==='fixed' && !expiryDate){
          showErrorToast('end date required for fixed type')
          return
        }
        const body:GenerateAccessCodeDTO={
          "charList": batchData.CharacterList,
          "numCodes": parseInt(batchData.NumberOfCodeNeed),
          "prefix": batchData.CodeStart,
          "suffix": batchData.CodeEnd,
          "expiryDate": batchData.LicenceType==='fixed' ?expiryDate:undefined 
        }
        await BatchesService.generateAccessCode( id!, body)

      }
      await BatchesService.updateBatch( id!, updateBatchData); 
      showSuccessToast('Batch updated!')
    } catch (error) {
      console.error('Error while updating the batch:', error);
      showErrorToast('Error updating batch.')
    }finally{
      setButtonLoading(false)
    }
  };
  
  if(loadingBatch || loadingBooks || loadingPublisher || loadingSchools){
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
        <CircularProgress/>
      </div>
    )
  }

  const validateForm = () => {
    if (!batchData?.Title?.trim()) {
        return false;
    }

    if (!selectedPublisher || !selectedBook || !selectedSchools) {
        return false;
    }

    // If in 'add' mode, validate access code fields
    if (mode === 'add') {
        if ((!batchData?.NumberOfCodeNeed || isNaN(parseInt(batchData.NumberOfCodeNeed)))) {
            return false;
        }

        if (batchData?.CodeLength === undefined || isNaN(parseInt(batchData.CodeLength.toString()))) {
            return false;
        }

        if (!batchData?.CodeStart?.trim()) {
            return false;
        }

        if (!batchData?.CodeEnd?.trim()) {
            return false;
        }

        if (!batchData?.CharacterList?.trim()) {
            return false;
        }
    }

    // Validate expiration days and expiry date based on selected value
    if (selectedValue === 'on use' && (expirationDay === undefined || expirationDay < 0)) {
        return false;
    }

    if (selectedValue === 'fixed' && !expiryDate) {
        return false;
    }

    return true;
};


  return (
    <div className={styles.background}>
      <div className={styles.Title}>Batch Information</div>
      <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.row}>
        <div className={styles.field}>
          <label>Title</label>
          <input
            value={batchData?.Title}
            onChange={(e) => setBatchData({ ...batchData, Title: e.target.value })}
            className={`${styles.fullWidth}`}
            disabled={mode === 'view'}
          />
        </div>  
      </div>
        <hr className={styles.hr}/>

        <div className={styles.row}>
          <DropdownSelector label="Publisher" options={publisherOptions} selectedOptions={selectedPublisher} onChange={handlePublisherChange} disabled={mode==='view'} />
        </div>
        <div className={styles.row}>
          <DropdownSelector label="Book" options={booksOptions}  selectedOptions={selectedBook} onChange={handleBookChange} disabled={selectedPublisher===null || mode==="view"} placeholder={selectedPublisher===null?"Select a publisher first":'Search...'}/>
        </div>
        <hr className={styles.hr}/>

        <div className={styles.row}>
          <DropdownSelector label="School" options={schoolsOptions} selectedOptions={selectedSchools} onChange={handleSchoolsChange} disabled={mode==='view'}/>
        </div>        
        <div className={styles.Title}>License Type</div>
        <div className={styles.row}>
          <div className={styles.field}>
            <RadioButton selectedValue={selectedValue} onChange={handleTypeChange}/>
          </div>
        </div>
 

        <div className={styles.row}>
          {selectedValue === 'on use' && (
          <div className={styles.field}>
              <label>Days</label>
              <Counter 
                  initialCount={0} 
                  counter={expirationDay || 0} 
                  onChange={(value) => {
                      setExpirationDays(value);
                      setBatchData({ ...batchData, Days: value });
                  }}
                  disabled={mode === 'view'} 
              />
        </div>
        )}
          {selectedValue === 'fixed' && (
            <div className={styles.field}>
                <label>Expiry Date</label>
                <input
                    type="date"
                    value={expiryDate || ""}
                    onChange={(e) => setExpiryDate(e.target.value)}
                    className={styles.textField}
                    disabled={mode === 'view'}
                />
            </div>
          )}
        </div>

        <div className={styles.Title}>Access Code Generation</div>
       
        <div className={styles.row}>


          <div className={styles.field}>
            <label>Number Of Codes Needed:</label>
            <input
              type="number"
              value={batchData?.NumberOfCodeNeed}
              onChange={handleNumberOfCode}
              className={styles.textField}
              disabled={mode === 'view'}
            />
          </div>
          <div className={styles.field}>
            <label>Code Length (Character)</label>
            <input
              type="number"
              value={batchData?.CodeLength}
              onChange={handleCodeLength}
              className={styles.textField}
              disabled={mode === 'view'}
            />
          </div>
        </div>
     
         <div className={styles.row}>
         <div className={styles.field}>
            <label>Code Start</label>
            <input
              type="text"
              value={batchData?.CodeStart}
              onChange={(e) => setBatchData({ ...batchData,CodeStart: e.target.value })}
               disabled={mode === 'view'}
               className={styles.textField}

            />
          </div>
          <div className={styles.field}>
            <label>Code End</label>
            <input
              type="text"
              value={batchData?.CodeEnd}
              onChange={(e) => setBatchData({ ...batchData,CodeEnd: e.target.value })}
               disabled={mode === 'view'}
               className={styles.textField}

            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <label>Character List</label>
            <input
              value={batchData?.CharacterList}
              onChange={(e) => setBatchData({ ...batchData,CharacterList: e.target.value })}
              className={`${styles.fullWidth} `}
  
              disabled={mode === 'view'}
            />
          </div>
        </div>
        
        {mode !== 'view' && (
          <div className={styles.buttonContainer}>
            {mode==='edit' && user?.role==='admin'?
            <>
              <button className={styles.DeleteButton} type="button" onClick={handleDelete}>DELETE</button>
              <button className={styles.ArchiveButton} type="button" onClick={handleActivate}>{batchData?.Active?"DEACTIVATE":'ACTIVATE'}</button>
              <button className={styles.ArchiveButton} type="button" onClick={handleArchive}>{batchData?.Archived?'UNARCHIVE':'ARCHIVE'}</button>
            </>
            :<></>}
            <button className={styles.AddButton} type="submit">{!buttonLoading?mode === 'add' ? 'ADD' : 'UPDATE':<CircularProgress style={{color:'white'}}/>}</button>
          </div>
        )}
      </form>
      <ArchiveModal
            setIsOpen={setModalOpen}

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={modalMode === 'archive' ? `${batchData?.Archived?'Unarchive':'Archive'} Batch` : modalMode==='activate'? `${batchData?.Active?'Deactivate':'Activate'} Batch`:  'Delete Batch'}
        parag={modalMode === 'archive' ? `Are you sure you want to ${batchData?.Archived?'unArchive':'archive'} this batch?` : modalMode==='activate'? `Are you sure you want to ${batchData?.Active?'deactivate':'activate'} this batch?`: 'Are you sure you want to delete this batch?'}
        buttonText={modalMode === 'archive' ? batchData?.Archived?'unarchive':'archive' : modalMode==='activate'? batchData?.Active?'deactivate':'activate'  : 'Delete'}
        mode={modalMode}
      />
    </div>
  );
}

export default BatchesForm;
