import React from 'react';
import ToggleButtons from '../ToggleButtons/ToggleButtons';
import { Link } from 'react-router-dom';
import AddButton from '../AddButton/AddButton';
import ExportButton from '../ExportButton/ExportButton';
import SearchBar from '../SearchBar/SearchBar';
import PaginationInfo from '../Pagination/PaginationInfo';
import { SelectChangeEvent } from '@mui/material';
import styles from '../../../pages/Publishers/Publishers.module.css';
interface Label {
    label_name: string;
    index: number;
  }
interface Props{
    activeButton: number;
    setActiveButton: (index: number) => void;
    dataLength: number;
    label: string;
    link: string;
    labelObject: Label[];
    searchTerm: string; 
    onChange: (value: string) => void;
    handlePageSizeChange: (event: SelectChangeEvent<unknown>) => void;
    pageSize: number;
    exportType: "publishers" | "schools" | "books" | "users";
}
const SubHeader: React.FC<Props> = ({
    activeButton,
    setActiveButton,
    dataLength,
    label,
    link,
    labelObject,
    searchTerm, 
    onChange,
    handlePageSizeChange,
    pageSize,
    exportType
}) => {
    return ( 
        <div>
            <div className='w-full items-center flex justify-between'>
                <ToggleButtons
                    labels={labelObject}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                />
                <div className='flex items-center'>
                    <Link to={link} className={styles.link}>
                        <AddButton label={label} />
                    </Link>
        
                    <ExportButton label="Export" exportType={exportType}/>
                </div>
            </div>
            <div className='w-full flex items-center justify-between py-[34px]'>
                <SearchBar searchTerm={searchTerm} onSearch={onChange}/>
                <div className=''>
                    <PaginationInfo totalItems={dataLength} pageSize={pageSize} handlePageSizeChange={handlePageSizeChange}/>
                </div>
            </div>
        </div>
    )
} 
export default SubHeader;