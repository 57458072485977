import React, { useEffect, useState } from 'react';
import AddButton from '../../components/Shared/AddButton/AddButton';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import Table from '../../components/Shared/Table/AdminAccountsTable';

import { Link, useNavigate } from 'react-router-dom';
import styles from './AdminAccounts.module.css';
import { SelectChangeEvent } from '@mui/material';
import { ArchiveResponse, UserDTO, UserResponseDTO } from '../../dto/UserDTO';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { UserService } from '../../services/UserService';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';

const AdminAccounts: React.FC = () => {
    const columns = [
        { title: 'Username', key: 'fullname' },
        { title: 'Email', key: 'email' },
        { title: 'Role', key: 'role' },
        { title: 'Created Date', key: 'dateCreated', isDate: true },
    ];

     
    const navigate = useNavigate();
    const [data,setData]=useState<UserDTO[]>([])
    const [total,setTotal]=useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm,setSearchTerm] = useState<string>("");
    const [loading,setLoading]=useState(true)
    const handleSearchChange = (value: string) => {
        setSearchTerm(value); 
        setCurrentPage(1)

        setLoading(true)

    };

    const [pageSize, setPageSize] = useState(10);
    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setPageSize(Number(event.target.value));
    };

    const handlePageChange = (page: number) => {
        setLoading(true)
        setCurrentPage(page);
    };

    const getRoleClass = (role: string) => {
        switch (role) {
            case 'admin':
                return styles.superAdmin;
            case 'editor':
                return styles.editor;
            case 'sales':
                return styles.sales;
            default:
                return '';
        }
    };
 
    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    const fetchUsers = async () => {
        try {
            
            const response: UserResponseDTO = await UserService.getAdminUsers( currentPage, pageSize, searchTerm);
            if (response && Array.isArray(response.users)) {
                const formattedUsers = response.users.map((user) => ({
                    ...user,
                    dateCreated: formatDate(user.dateCreated),
                    roleClass: getRoleClass(user.role),
                }));
                setData(formattedUsers);
                setTotal(response.total);
            }
        } catch (error) {
            showErrorToast('Error fetching the users.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();        
    }, [currentPage, pageSize, searchTerm]); 

    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [mode, setMode] = useState<'activate' | 'delete'>('activate'); 
    const [showModal, setShowModal] = useState(false);

    const handleConfirmAction = async (): Promise<void> => {
        if (selectedUserId) {
            
                try {
                    if (mode === 'activate') {
                        const response:ArchiveResponse=await UserService.toggleActive( selectedUserId);
                        showSuccessToast(response.message)
                    } else if (mode === 'delete') {
                        await UserService.deleteUser( selectedUserId);
                        showSuccessToast('user deleted!')
                    }
                    setData(data.filter(user => user._id !== selectedUserId));
                    setSelectedUserId(null);
                } catch (error) {
                    showErrorToast(`Error ${mode === 'activate' ? 'toggling active' : 'deleting'} admin account`)
                    console.error(`Error ${mode === 'activate' ? 'Activating' : 'deleting'} admin account:`, error);
                }
            
        }
        setShowModal(false);
    };

    const handleCancelAction = (): void => {
        setSelectedUserId(null);
        setShowModal(false);
    };

    const handleArchiveClick = (id: string): void => {
        setSelectedUserId(id);
        setMode('activate'); 
        setShowModal(true);
    };

    const handleDeleteClick = (id:string): void => {
        setSelectedUserId(id);
        setMode('delete');
        setShowModal(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>                
                <Link to="/adminaccounts/add" className={styles.link}>
                    <AddButton label="ADD ACCOUNT" />
                </Link>
                <ExportButton label="Export" exportType='users'/>                 
            </div>
            <div className={styles.content2}>
                <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
                <PaginationInfo totalItems={total} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}/>
            </div>
            <Table
                columns={columns}
                data={data}
                onEdit={(id:string) => {
                    navigate(`/adminaccounts/edit/${id}`);
                }}
                onDelete={handleDeleteClick}
                onArchive={handleArchiveClick}
                loading={loading}
            />
            <div className={styles.content3}>
                <Pagination3 totalPages={Math.ceil(total / pageSize)} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
            
            <ArchiveModal
            setIsOpen={setShowModal}
                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode === 'activate' ?'Deactivate' : 'Delete'} this user?`}
                parag={`Sure you want to ${mode === 'activate' ? 'Deactivate' : 'Delete'} this user?`}
                mode={mode} 
                buttonText={mode==='activate' ?'Yes, Deactivate': 'Yes, delete'}

            />
        </div>
    );
}

export default AdminAccounts;
