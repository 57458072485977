import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material';

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  
});

const ItemsInfo = styled(Typography)({
  color: '#828282',
  width:'170px',
  fontSize: 13,
  textAlign: 'left',
});

const PageSizeSelect = styled(Select)({
  border: '0.5px solid #828282',
  borderRadius: '24px',
  height: '29px',
  width: '60px',
  '& .MuiSelect-select:focus': {
    backgroundColor: '#f0f0f0',
  },
  '& .MuiSelect-select': {
    textAlign: 'left',
    paddingLeft:"8px",
    color: '#828282',
    fontSize: 13,
  },
});

interface PaginationInfoProps {
  totalItems: number;
  pageSize:number;
  handlePageSizeChange:(event:SelectChangeEvent<unknown>)=>void
}

const PaginationInfo: React.FC<PaginationInfoProps> = ({ totalItems,pageSize,handlePageSizeChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  
  

  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  return (
    <Container>
      <ItemsInfo>
        Displaying {startItem}-{endItem} of {totalItems} items
      </ItemsInfo>
      <PageSizeSelect
        value={pageSize}
        onChange={handlePageSizeChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Items per page' }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </PageSizeSelect>
    </Container>
  );
};

export default PaginationInfo;
