import React, { useContext, useEffect } from 'react'
import styles from './NotAuthorized.module.css'
import { ReactComponent as Lock } from '../../images/Shared/lock-noauthorized.svg';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
function NotAuthorized() {   
  return (
    <div  className={styles.container}>
        <Lock className={styles.image}/>
        <div className={styles.body}>
            <h1 className={styles.header}>Access Denied</h1>
            <p>You do not have permission to access this page.</p>
        </div>
    </div>
  )
}

export default NotAuthorized