import React, { useEffect, useState } from 'react';
import styles from './Counter.module.css';
import { ReactComponent as PlusIcon } from '../../images/Books/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../images/Books/MinusIcon.svg';

interface PagesCounterProps {
  initialCount?: number;
  count: number;
  setCount: (value: number) => void;
}

const Counter: React.FC<PagesCounterProps> = ({ initialCount = 1, count, setCount }) => {
  const [inputValue, setInputValue] = useState<string>(count.toString() === '0' ? '1' : count.toString());

  useEffect(() => {
    setInputValue(count === 0 ? '1' : count.toString());
  }, [count]);

  const handleBlur = () => {
    const value = parseInt(inputValue, 10);
    if (!isNaN(value) && value > 0) {
      setCount(value);
    } else {
      setInputValue(count === 0 ? '1' : count.toString());
    }
  };

  const handleIncrease = () => {
    setCount(count + 1);
  };

  const handleDecrease = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setInputValue(value);
    }
  };

  return (
    <div className={styles.counterContainer}>
      <button onClick={handleDecrease} type='button' className={styles.button}>
        <MinusIcon className={styles.icon} />
      </button>

      <input
        type="number"
        className={styles.input}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        min={1}
      />
      <button onClick={handleIncrease} type='button' className={styles.button}>
        <PlusIcon className={styles.icon} />
      </button>
    </div>
  );
};

export default Counter;
