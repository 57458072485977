import React, { useRef, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { ReactComponent as ImageIcon } from '../../images/Shared/Image.svg';
import styles from './UploadImage.module.css';

interface UploadSectionProps {
  title: string;
  setBookData: (value: File) => void;
  imageUrl?: string;  
  mode:string;
}


const UploadSection: React.FC<UploadSectionProps> = ({ title, setBookData, imageUrl,mode }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    if (imageUrl) {
      setPreviewImage(imageUrl);
    }
  }, [imageUrl]);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setBookData(file);

      const filePreviewUrl = URL.createObjectURL(file);
      setPreviewImage(filePreviewUrl);
    }
  };

  return (
    <Box>
      <div className={styles.Title}>{title}</div>
      <Box className={styles.uploadBox}>
        {previewImage ? (
          <div>
            <img src={previewImage} alt="Uploaded" className={styles.previewImage} />
          </div>
        ) : (
          <ImageIcon />
        )}
        
        {!previewImage ?
        <div className={styles.text}>
          Upload or drop image
        </div>
        :null}
        {mode!=='view'&&
          <>
            <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Button
            className={styles.uploadButton}
            variant="contained"
            style={{
              backgroundColor: '#00726B',
              color: '#FFFFFF',
            }}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        </>}
      </Box>
    </Box>
  );
};

const PublisherImages: React.FC<{ setBookData: (value: File) => void; imageUrl?: string, mode:string}> = ({ setBookData, imageUrl,mode }) => (
  <Box>
    <UploadSection title="Upload Cover" setBookData={setBookData} imageUrl={imageUrl} mode={mode}/>
  </Box>
);

export default PublisherImages;
