import React, { useEffect, useState } from 'react';
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';
import AddButton from '../../components/Shared/AddButton/AddButton3';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';


import { Link, useNavigate } from 'react-router-dom';

import Table from '../../components/Shared/Table/AnnouncementsTable';

import styles from './Announcements.module.css'; 
import { AnnouncementsService } from '../../services/AnnouncementsService';
import { SelectChangeEvent } from '@mui/material';
import { AnnouncementsDTO, ArchiveAnnouncementsResponseDTO } from '../../dto/AnnouncementsDTO';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';

const Announcements: React.FC = () => {
    const columns = [
        { title: 'Cover Image' },
        { title: 'Title' },
        { title: 'Created Date' },
    ];
    
    const [annoucements,setAnnoucements]=useState<AnnouncementsDTO[]>([])
    const [activeButton, setActiveButton] = useState(0);  

    //fetch data
    useEffect(()=>{
        const fetchData=async()=>{
            try{
                let archived;
                if(activeButton===0){
                    archived=false
                }else{
                    archived=true
                }
                const response=await  AnnouncementsService.getAnnouncements(archived);
                if(Array.isArray(response)){
                    setAnnoucements(response)
                }
            }catch(error){
                showErrorToast("Error while fetching the annoucements.")
                console.error("Error while fetching the annoucements",error)
            }finally{
                setLoading(false)
            }
        }
        fetchData()
    },[activeButton])

    const navigate = useNavigate();
    
    const [showModal,setShowModal]=useState(false)
    const [mode,setMode]=useState<'delete'|"archive">('archive');
    const [selectedId,setSelectedId]=useState<string|null>(null)
    
    const [loading,setLoading]=useState(true)


     const handleEditClick = (id: number) => {
        navigate(`/announcements/edit/${id}`);
    };
    
    const handleViewClick = (id: string) => {
        navigate(`/announcements/view/${id}`);
    };

 
    const label = [
        {
            label_name: "Published",
            index: 0
        }, 
        { 
            label_name: "Archived",
            index: 1
        }
    ]

    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    const handleDeleteClick=async(id:string)=>{
        setSelectedId(id)
        setMode('delete')
        setShowModal(true)
    }
    const handleArchiveClick=async(id:string)=>{
        setSelectedId(id)
        setMode('archive')
        setShowModal(true)
    }

    const handleConfirmAction = async (): Promise<void> => {
        if (selectedId) {
            
                try {
                    setLoading(true)
                    setSelectedId(null);
                    if (mode === 'archive') {
                        const message:ArchiveAnnouncementsResponseDTO=await  AnnouncementsService.toggleArchive(selectedId);
                        showSuccessToast(message.message);
                    } else if (mode === 'delete') {
                        const message=await  AnnouncementsService.deleteAnnouncement( selectedId);
                        showSuccessToast(message);
                    }
                    setAnnoucements(annoucements.filter(publisher => publisher._id !== selectedId));
                } catch (error) {
                    console.error(`Error ${mode === 'archive' ? 'archiving' : 'deleting'} annoucement:`, error);
                    showErrorToast(`Error ${mode === 'archive' ? 'toggling archive' : 'deleting'} annoucement.`);
                }finally{
                    setLoading(false)
                }
            
        }
        setShowModal(false);
    };

    const handleCancelAction = (): void => {
        setSelectedId(null);
        setShowModal(false);
    };
    const handleSetActiveButton=(value:number)=>{
        setLoading(true)
        setActiveButton(value)
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleSetActiveButton} />
                <div className='flex '>
                    <Link to="/announcements/add" className={styles.link}>
                        <AddButton label="ADD ANNOUNCEMENT" />
                    </Link>
                    <ExportButton label="Export" exportType='announcements'/> 
                </div>
            </div>
            {/* <div className={styles.content2}>
                <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
                <PaginationInfo totalItems={annoucements.length} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}/>                
            </div> */}
            <Table
                columns={columns}
                data={annoucements.map((publisher:AnnouncementsDTO, index) => (
                    {
                        '_id':publisher._id,
                        'Cover Image':publisher.imageUrl ,
                        'Title':publisher.title,
                        'Created Date':formatDate(publisher.dateCreated) ,
                        "archived":publisher.archived
                    }
                ))}
                onEdit={handleEditClick}
                onView={handleViewClick}
                onArchive={handleArchiveClick}
                onDelete={handleDeleteClick}
                loading={loading}
            />
            {/* <div className={styles.content3}>
                <Pagination3 totalPages={10} currentPage={currentPage} onPageChange={handlePageChange} />
            </div> */}
            <ArchiveModal
            setIsOpen={setShowModal}

                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode==='archive' ? activeButton===0?'archive':"unarchive" : 'delete'} this annoucement?`}
                parag={`Are you sure you want to ${mode==='archive'?activeButton===0?"archive":'unarchive':'delete'} this annoucementr?`}
                mode={mode}
                buttonText={mode==='archive' ? activeButton===0?'Yes, archive':"Yes, unarchive" : 'Yes, delete'}

            />
        </div>
    );
}

export default Announcements;
