import React, { useEffect, useState } from 'react';
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';
import AddButton from '../../components/Shared/AddButton/AddButton';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';

import { Link, useNavigate } from 'react-router-dom';

import Table from '../../components/Shared/Table/BatchesTable';
import styles from './Batches.module.css';
import { BatchesService } from '../../services/BatchesService';
import { BatchDTO, BatchResponseDTO } from '../../dto/BatchesDTO';
import { SelectChangeEvent } from '@mui/material';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';

const Batches: React.FC = () => {
    const columns = [
        { title: 'Batch Title' },
        { title: 'Type' },
        { title: 'Publisher' },
        { title: 'Book Cover' },
        { title: 'Book Title' },
        { title: 'School' },
        { title: 'All Codes' },
        { title: 'Used Codes' },
        { title: 'Created Date' },
    ];

    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(0);
    const [batches,setBatches]=useState<BatchDTO[]>([]);
    const [total,setTotal]=useState(0);
    
    
    const [showModal,setShowModal]=useState(false)
    const [selectedBatch,setSelectedBatch]=useState<BatchDTO|null>(null)
    const [mode, setMode] = useState<'archive' | 'delete'|'publish'>('archive'); 
    

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm,setSearchTerm] = useState<string>("");

    const [loading,setLoading]=useState(false);

    const handleConfirmAction = async (): Promise<void> => {
        if (selectedBatch) {
      
                try {
                    if (mode === 'archive') {
                        const message=await BatchesService.toggleArchive( selectedBatch._id);
                        showSuccessToast(message);
                    } else if (mode === 'delete') {
                        const message=await BatchesService.deleteBatch(selectedBatch._id);
                        showSuccessToast(message);
                    }else if(mode ==='publish'){
                        const message  =await BatchesService.toggleActivate( selectedBatch._id);
                        showSuccessToast(message);
                    }
                    setSelectedBatch(null);
                    if(mode==='archive' &&activeButton===2){
                        setBatches(batches.filter(batch => batch._id !== selectedBatch._id));
                    }else if(mode==='publish' && (activeButton===0 || activeButton===1)){
                        setBatches(batches.filter(batch => batch._id !== selectedBatch._id));
                    }else if(mode ==='delete'){
                        setBatches(batches.filter(batch => batch._id !== selectedBatch._id));
                    }
                } catch (error) {
                    console.error(`Error ${mode === 'archive' ? 'archiving' : 'deleting'} batch:`, error);
                    if (mode === 'archive') {
                       showErrorToast('Error toggling archive batch.')
                    } else if (mode === 'delete') {
                        showErrorToast('Error deleting batch.')

                    }else if(mode ==='publish'){
                        showErrorToast('Error toggling publish batch.')

                    }
                }
            
        }
        setShowModal(false);
    };

    const handleCancelAction = (): void => {
        setSelectedBatch(null);
        setShowModal(false);
    };

    const handleArchiveClick = (id: BatchDTO): void => {
        setSelectedBatch(id);
        setMode('archive');
        setShowModal(true);
    };

    const handlePublishClick = (id: BatchDTO): void => {
        setSelectedBatch(id);
        setMode('publish');
        setShowModal(true);
    };

    const handleDeleteClick = (id:BatchDTO): void => {
        setSelectedBatch(id);
        setMode('delete'); 
        setShowModal(true);
    };

    const handleSearchChange = (value: string) => {
        setLoading(true)
        setCurrentPage(1)
        setSearchTerm(value); 
    };

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setPageSize(Number(event.target.value));
        setCurrentPage(1)
    };

    const handlePageChange = (page: number) => {
        setLoading(true)   
        setCurrentPage(page);
    };

    const handleEditClick = (id: string) => {
        navigate(`/batches/edit/${id}`);
    };

    const handleViewClick = (id: string) => {
        navigate(`/batches/view/${id}`);
    };

    
    

    //fetch data
    useEffect(() => {  
        const fetchBatches = async () => {
            try {
                setLoading(true)
            
                const active=activeButton===0?true:activeButton===1?false:null
                const archived=activeButton===2?true:null
                const batchData:BatchResponseDTO = await BatchesService.getBatches(currentPage,pageSize,active,archived,searchTerm);
                if(batchData && batchData.batches && Array.isArray(batchData.batches)){
                    setTotal(batchData.total)
                    setBatches(batchData.batches);
                }
                
            } catch (error) {
                showErrorToast('Error while fetching batches.')
                console.error(error);
            }finally{
                setLoading(false)
            }
        };
        fetchBatches();
    }, [currentPage,pageSize,activeButton,searchTerm]);

    const label = [
        { label_name: "Active", index: 0 },
        { label_name: "Deactivated", index: 1 },
        { label_name: "Archived", index: 2 },
    ];

    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    const tableData = batches?.map((batch) => {
         return {
            '_id': batch._id,
            'Batch Title': batch.title || null,
            'Type': batch.licenseType || null,
            'Publisher': batch.book?.publisher?.fullname || null,
            'Book Cover': <img src={batch.book?.bookCoverUrl || ''} alt="Book Cover" className={styles.bookCover}/>, 
            'Book Title': batch.book?.bookTitle || null,
            'School': batch.school?.name || null,
            'All Codes': batch.totalAccessCodes || null,
            'Used Codes': batch.activatedAccessCodes||0,
            'Created Date': formatDate(batch.dateCreated),
            'Active':batch.active,
            'Archived':batch.archived
        };
    });
    const handleSetActiveButton=(value:number)=>{
        setActiveButton(value)
        setCurrentPage(1)
    }
    return (
        <div className={styles.container}>
            <div className={styles.content}>
            <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleSetActiveButton} />
            <div className='flex'>
                <Link to="/batches/add" className={styles.link}>
                    <AddButton label="ADD BATCH" />
                </Link>
                <ExportButton label="Export" exportType='batches' />
            </div>
            </div>
            <div className={styles.content2}>
                <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
                <PaginationInfo totalItems={total}  pageSize={pageSize} handlePageSizeChange={handlePageSizeChange}/>
            </div>
            <Table
                columns={columns}
                data={tableData}
                onEdit={handleEditClick}
                loading={loading}
                handleActivate={handlePublishClick} 
                handleArchive={handleArchiveClick}
                handleDelete={handleDeleteClick}
                onView={handleViewClick}
            />
            <div className={styles.content3}>
                <Pagination3 totalPages={Math.ceil(total / pageSize)} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
            <ArchiveModal
                setIsOpen={setShowModal}
                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode==='archive' ? selectedBatch?.Archived===false?'archive':"Unarchive" : mode==='delete'? 'delete':mode==='publish'? selectedBatch?.Active===true?'Deactivate':"Activate":''} this batch?`}
                parag={`Sure you want to ${mode==='archive' ? selectedBatch?.Archived===false?'archive':"Unarchive" : mode==='delete'? 'delete':mode==='publish'? selectedBatch?.Active===true?'Deactivate':"Activate":''} this batch?`}
                mode={mode}
                buttonText={mode==='archive' ? selectedBatch?.Archived===false?'Yes, archive':"Yes, Unarchive" : mode==='delete'? 'Yes, delete':mode==='publish'? selectedBatch?.Active===true?'Yes, Deactivate':"Yes, Activate":''}
            />
        </div>
    );
}

export default Batches;
