import { CreateUserDTO, UpdateUserDTO } from "../dto/UserDTO";
import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class UserService {
    // Create a new user
    public static createUser = async ( newUser: CreateUserDTO): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + '/user/createUserFromAdmin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newUser),
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to create user');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in createUser:", error);
            throw error;
        }
    };

    // Get the current user
    public static getUser = async (): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/getUser`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch user');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in getUser:", error);
            throw error;
        }
    };

    // Get a specific user by ID
    public static getUserAdmin = async ( id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/getUser/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch user details');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in getUserAdmin:", error);
            throw error;
        }
    };

    // Get all users
    public static getAllUsers = async (
        
        page: number,
        limit: number,
        active: boolean,
        name: string
    ): Promise<any> => {
        try {
            const param = new URLSearchParams({
                limit: String(limit),
                page: String(page),
                active: String(active),
                ...(name && { fullname: name }),
            });

            const resp = await FetchAuth(endpoint + `/user/getAllUsersAdmin?${param.toString()}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch users');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in getAllUsers:", error);
            throw error;
        }
    };

    // Get admin users
    public static getAdminUsers = async (
        
        page: number,
        limit: number,
        name: string
    ): Promise<any> => {
        try {
            const params = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                ...(name.length > 0 && { fullname: name }),
                active: String(true),
            });

            const resp = await FetchAuth(endpoint + `/user/getAllAdminUsers?${params.toString()}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch admin users');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in getAdminUsers:", error);
            throw error;
        }
    };

    // Add a book to a user
    public static addBook = async ( selectedAccessCode: string, selectedBook: string, userId: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/accessBookAdmin/${userId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ accessCode: selectedAccessCode, bookId: selectedBook }),
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to add book');
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error("Error in addBook:", error);
            throw error;
        }
    };

    // Remove a book from a user
    public static removeBook = async ( bookId: string, userId: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/removeBook/${userId}/${bookId}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to remove book');
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error("Error in removeBook:", error);
            throw error;
        }
    };

    // Toggle active status of a user
    public static toggleActive = async ( id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/toggleActive/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to toggle active status');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in toggleActive:", error);
            throw error;
        }
    };

    // Delete a user
    public static deleteUser = async ( id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/deleteUserAdmin`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ 'uid': id }),
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to delete user');
            }

            return resp;
        } catch (error) {
            console.error("Error in deleteUser:", error);
            throw error;
        }
    };

    // Update a user
    public static updateUser = async ( id: string, newUser: UpdateUserDTO): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/updateUser/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newUser),
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to update user');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in updateUser:", error);
            throw error;
        }
    };

    // Check if an email exists
    public static checkEmail = async ( email: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/user/check-email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ 'email': email }),
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to check email');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in checkEmail:", error);
            throw error;
        }
    };

    // Update user password
    public static updatePassword = async ( newPassword: string, id: string): Promise<void> => {
        try {
            const response = await FetchAuth(endpoint + '/user/updateUserPassword', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: id,
                    newPassword: newPassword,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update password');
            }
        } catch (error) {
            console.error("Error in updatePassword:", error);
            throw error;
        }
    };

    // Get file export (excel, csv, or pdf)
    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportUsers?format=${format}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch file');
            }

            const blob = await resp.blob();
            return blob;
        } catch (error) {
            console.error("Error in getFile:", error);
            throw error;
        }
    };
}

