import React, { useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import './UsersFormTable.css';
import { MyLibraryDTO } from '../../../dto/UserDTO';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { showErrorToast, showSuccessToast } from '../Toastify/toastHelper';
import { UserService } from '../../../services/UserService';

// New interface to represent each row in the table
interface FlattenedLibraryData {
  _id: string;
  accessCode: string;
  bookTitle: string;
  batchTitle: string;
  activationDate: string;
  expirationDate: string;
  bookCover:string;
}

interface Column {
  title: string;
  key: keyof FlattenedLibraryData; 
}

interface TableProps {
  columns: Column[];
  data: MyLibraryDTO[] | [];
  onEdit?: (id: string) => void;
  userId?:string
}

const CustomTable: React.FC<TableProps> = ({ columns, data, onEdit,userId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleDelete=async(bookId:string)=>{
    try {
      
      const response =await UserService.removeBook(bookId,userId!);
      showSuccessToast(response)
    } catch (error) {
      console.error('error while deleting book: ',error)
      showErrorToast('error while deleting book: '+error)
    }
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSort = (columnTitle: string) => {
    const isAscending = sortColumn === columnTitle && sortDirection === 'asc';
    setSortColumn(columnTitle);
    setSortDirection(isAscending ? 'desc' : 'asc');
  };

  // Flatten myLibrary to show individual books as rows
  const flattenedData: FlattenedLibraryData[] = data?.map((libraryItem) =>
      ({
        _id: libraryItem._id,
        accessCode: libraryItem.accessCode,
        bookTitle: libraryItem.bookId?.bookTitle,
        batchTitle: libraryItem.batchTitle || '',
        activationDate: libraryItem.activationDate || '',
        expirationDate: libraryItem.expirationDate || '',
        bookCover:libraryItem.bookId?.bookCoverUrl
      })
  );
  
  const sortData = (data: FlattenedLibraryData[]) => {
    if (!sortColumn) return data;

    return data.slice().sort((a, b) => {
      const aValue = a[sortColumn as keyof FlattenedLibraryData];
      const bValue = b[sortColumn as keyof FlattenedLibraryData];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      return 0;
    });
  };

  const sortedData = sortData(flattenedData);

  return (
    <div className="TableContainer">
      <div className="Table">
        <div className='headerRow'>
            {columns.map((column, index) => (
              <div key={index} onClick={() => column.title !== 'Cover' && handleSort(column.title)} className='headerFeild'>
                <div className="HeaderCell">
                  {column.title !== 'Cover' && (
                    <ArrowIcon className={`ArrowIcon ${sortColumn === column.title ? sortDirection : 'default'}`} />
                  )}
                  {column.title}
                </div>
              </div>
            ))}
        </div>
          {sortedData.length>0? 
            sortedData.map((row, rowIndex) => (
              <div key={rowIndex} className="tableRow">
                {columns.map((column, colIndex) => (
                  columns.length-1!==colIndex?
                    (<div key={colIndex} className='field'>
                      {column.key === 'bookCover' ? (
                        <img src={row.bookCover} alt={`${row.bookTitle} cover`} className="book-cover-image" />
                      ) : (
                        <p className='fieldText'>{row[column.key] || <i>null</i>}</p>
                      )}
                    </div>)
                  :
                    <div key={colIndex} className='field'>                
                      <p style={{overflow:'hidden',minWidth:0}}>{row[column.key]}</p>
                      <div className={'actions'} onClick={() => toggleDropdown(rowIndex)}>
                        <DetailsIcon
                          className={'detailsIcon'}
                        />
                        {dropdownOpen === rowIndex && (
                          <div className={'dropdownMenu'}>
                            <div className={`dropdownItem delete`} onClick={()=>handleDelete(row._id)}>
                              Delete
                            </div>
                          </div>
                        )}
                      </div>
                  </div>
                ))}
              </div>
           ))
          :<p style={{padding:'16px 16px', display:'flex',alignItems:'center',justifyContent:'center'}}>No Books Found</p>
          }
      </div>

    </div>
  );
};

export default CustomTable;
