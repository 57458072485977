import React from 'react';
import styles from './TopBooksSection.module.css';
import { topValidatedBookDTO } from '../../dto/DashboardDTO';
import { CircularProgress } from '@mui/material';

interface Book {
  title: string;
  cover: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  grade: number;
}

interface Props{
  books:topValidatedBookDTO[] | null;
  loading:boolean

}

const TopBooksSection: React.FC<Props> = ({books,loading}) => {
  return (
    <div className={styles.container}>
      <div className={styles.Title}>Top 5 Validated Books</div>

      <div className={styles.booksContainer}>
        {!loading?
          books && books.length >0?
            books.map((book, index) => (
            <div key={index} className={styles.bookCard}>
              <img src={book.bookCoverUrl}className={styles.bookCover} />
              <div className={styles.bookTitle}>{book.bookTitle}</div>
              {book.grades.length>0 &&
                <div className={styles.bookGrade}>
                    {
                        book.grades?.map((g,index)=> {
                          if(index===book.grades.length-1){
                            return g.engName || null
                          }else{
                            return g.engName+' - ' || null
                          }
                        }) 
                      }
                </div>
              }           
            </div>
          )):<p>No data found</p>
        :
        <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
        <CircularProgress/>
        </div>
        }
      </div>
    </div>
  );
};

export default TopBooksSection;
