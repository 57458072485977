import React, { useState } from 'react';

import AccessCodeUsageSchool from '../../components/Reports/AccessCodeUsageSchool';
import AccessCodeUsageBook from '../../components/Reports/AccessCodeUsageBook';
import AccessCodeUsagePublisher from '../../components/Reports/AccessCodeUsagePublisher';
import AccessCodeUsageBatch from '../../components/Reports/AccessCodeUsageBatch';
import TopDownloadedBooks from '../../components/Reports/TopDownloadedBooks';

import styles from './Reports.module.css'; 

const Schools: React.FC = () => {



    return (
        <div className={styles.container}>
    
            <div className={styles.content2}>
                <AccessCodeUsageSchool/>
                <AccessCodeUsageBook/>
                <AccessCodeUsagePublisher/>
                <AccessCodeUsageBatch/>
                <TopDownloadedBooks/>
                
            </div>
          
           
        </div>
    );
}

export default Schools;
