import React from 'react';
import styles from './FileUploadProgress.module.css';

import { ReactComponent as CancelIcon } from '../../images/Books/CancelIcon.svg';

interface FileUploadProgressProps {
  fileName: string;
  uploadedSize: number;
  totalSize: number;
  onCancel: () => void;

}

const FileUploadProgress: React.FC<FileUploadProgressProps> = ({ fileName, uploadedSize, totalSize, onCancel}) => {
  

  return (
    <div className={styles.fileUploadProgress}>
      <div className={styles.uploadContainer}>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'flex-start'}}>
          <div className={styles.fileInfo}>
            <span className={styles.fileName}>{fileName}</span>
          </div>

          
          <div className={styles.fileSize}>
            {totalSize} MB
          </div>
        </div>
        <button className={styles.cancelContainer} type="button" onClick={onCancel}>
            <CancelIcon className={styles.cancelIcon}  />
        </button>
      </div>
    </div>
  );
};

export default FileUploadProgress;
