import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import styles from './BatchesTable.module.css';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { showSuccessToast } from '../Toastify/toastHelper';
import { UserContext } from '../../../UserContext';
import { BatchDTO } from '../../../dto/BatchesDTO';

interface TableColumn {
  title: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  onEdit: (id: string) => void;
  handleActivate: (id: BatchDTO) => void;
  handleArchive: (id: BatchDTO) => void;
  handleDelete:(id:BatchDTO)=>void
  loading:boolean
  onView:(id:string) => void;
}

const Table: React.FC<TableProps> = ({ columns, data, onEdit,loading,handleActivate,handleArchive,handleDelete,onView }) => {
  const{user}=useContext(UserContext)
  const navigate=useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const dropdownRef =useRef<HTMLDivElement|null>(null)
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1); 
  
  
  const handleSort = (column: string) => {
    console.log(column)
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const isDateColumn = (columnTitle: string) => {
    return columnTitle.toLowerCase().includes('date');
  };

  const isNumericColumn = (columnTitle: string) => {
    return ['casa id', 'total batches'].includes(columnTitle.toLowerCase());
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortColumn) {
      if (isDateColumn(sortColumn)) {
        const dateA = new Date(a[sortColumn]);
        const dateB = new Date(b[sortColumn]);
        return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      } else if (isNumericColumn(sortColumn)) {
        const numA = parseFloat(a[sortColumn]);
        const numB = parseFloat(b[sortColumn]);
        return sortDirection === 'asc' ? numA - numB : numB - numA;
      } else {
        const aValue = a[sortColumn].toString().toLowerCase();
        const bValue = b[sortColumn].toString().toLowerCase();
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      }
    }
    return 0;
  });

  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  useEffect(()=>{
    const handleClickOutside=(event:MouseEvent)=>{
      if( dropdownRef.current && !dropdownRef.current.contains(event.target as Node)){
        setDropdownOpen(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return ()=>{
          document.removeEventListener('mousedown', handleClickOutside)
        }
  },[])


  const handlePageChange = (page: number) => {
      setCurrentPage(page);
  };

 
  const handleViewCodes=(id:string)=>{
    navigate(`/accesscodes/${id}`)
  }

  const selectedRowIndex = useRef<string | null>(null);
  const selectedFullText = useRef<string | undefined>(undefined);

  const handleRowClick = (id:string, fullText?: string) => {
    selectedRowIndex.current = id;
    selectedFullText.current = fullText;
  };


  useEffect(() => {
    const handleMouseUp = () => {
      const textSelected = window.getSelection()?.toString();
      if (textSelected && selectedFullText.current) {
        navigator.clipboard.writeText(selectedFullText.current).then(() => {
          showSuccessToast('Text copied to clipboard');
        });
      } else if (selectedRowIndex.current !== null) {
        onView(selectedRowIndex.current);
      }

      selectedRowIndex.current = null;
      selectedFullText.current = undefined;
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onView]);

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader} style={{gridTemplateColumns:`repeat(${columns.length},1fr)`}}>
          {columns.map((column, index) => (
            <div key={index} className={styles.tableHeaderCell} onMouseDown={() => handleSort(column.title)}>
              {column.title !== 'Book Cover' && column.title !== 'Logo' && (
                <ArrowIcon
                  className={styles.arrowIcon} 
                />
              )}
              {column.title}
            </div>
          ))}

        </div>
        <div className={styles.tableBody}>

          {!loading?
            sortedData?.length? sortedData.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <div className={styles.tableRow} 
                    style={{gridTemplateColumns:`repeat(${columns.length},1fr)`}} 
                    onMouseDown={(event)=>{event.stopPropagation() ; handleRowClick(row._id)}}
                  >
                  {columns.map((column, colIndex) => (
                    <div key={colIndex} className={styles.tableCell} title={row[column.title]} onMouseDown={(event)=>{event.stopPropagation() ; handleRowClick(row._id,row[column.title])}}>
                      {colIndex!==columns.length - 1 ?
                        (
                          <span  onMouseDown={(event)=>{event.stopPropagation() ; handleRowClick(row._id,row[column.title])}} title={row[column.title] || 'null'}>
                            {row[column.title]!==null ?row[column.title]:<i>null</i>}
                          </span>
                        )
                        :(
                          <div style={{display:'flex', alignItems:"center",justifyContent:"space-between",width:'100%'}}>
                          <span  onMouseDown={(event)=>{event.stopPropagation() ; handleRowClick(row._id,row[column.title])}}>
                            {row[column.title]?row[column.title]:<i>null</i>}
                          </span>
                            <div className='relative'>
                              <DetailsIcon
                                className={styles.detailsIcon}
                                onMouseDown={(event) => {event.stopPropagation();toggleDropdown(rowIndex)}}
                              />
                                {dropdownOpen === rowIndex && (
                                  <div className={styles.dropdownMenu} ref={dropdownRef}>
                                   {user?.role==='admin'?
                                   <>
                                    <div className={styles.dropdownItem} onMouseDown={(event)=>{event.stopPropagation();handleArchive(row)  ;toggleDropdown(rowIndex)}}>{row.Archived===true?'Unarchive':'Archive'}</div>
                                    <div className={styles.dropdownItem} onMouseDown={(event)=>{event.stopPropagation();handleActivate(row) ;toggleDropdown(rowIndex)}}>{row.Active===true?"Deactivate":"Activate"}</div>
                                    <div className={styles.dropdownItem} onMouseDown={(event)=>{event.stopPropagation();handleViewCodes(row._id) ;toggleDropdown(rowIndex)}}>View Codes</div>
                                    </>
                                    :<></>}
                                    <div
                                      className={styles.dropdownItem}
                                      onMouseDown={(event) =>{event.stopPropagation(); onEdit(row._id);toggleDropdown(rowIndex)}}
                                    >
                                      Edit
                                    </div>
                                    {user?.role==='admin'?
                                    <div className={`${styles.dropdownItem} ${styles.delete}`} onMouseDown={(event)=>{event.stopPropagation();handleDelete(row);toggleDropdown(rowIndex) }}>
                                      Delete
                                    </div>:<></>}
                                  </div>
                                )}
                            </div>
                          </div>
                        )
                      }
                    </div>
                  ))}
                  
                </div>
                
              </React.Fragment>
              ))
              :   
              <div className='text-center mt-5'>
                <h1>No data found</h1>
              </div>
              :
              <div className='text-center mt-5'>
                <CircularProgress/>
              </div>
          }
        </div>

     
      </div>
      
    </div>
  );
};

export default Table;
