import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class PublishersService {
    // Get all publishers with search and pagination
    public static getPublishers = async ( archived: boolean, limit: number, page: number, searchTerm: string): Promise<any> => {
        try {
            const param = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
                archived: archived.toString(),
                ...(searchTerm && { name: searchTerm })
            });

            const resp = await FetchAuth(`${endpoint}/publisher/admin/getAllPublishers?${param.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch publishers');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error('Error in getPublishers:', error);
            throw error;
        }
    };

    // Get publisher details by ID
    public static getPublisherById = async (_id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/publisher/getPublisher/${_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch publisher details');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error('Error in getPublisherById:', error);
            throw error;
        }
    };

    // Create a new publisher
    public static createPublisher = async (publisher: FormData): Promise<any> => {
        try {
            const response = await FetchAuth(`${endpoint}/publisher/createPublisher`, {
                method: 'POST',
                body: publisher,  // Don't set Content-Type when sending FormData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to create publisher');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error in createPublisher:', error);
            throw error;
        }
    };

    // Update publisher details
    public static updatePublisher = async ( publisherData: FormData): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/publisher/editPublisher/${publisherData.get('uid')}`, {
                method: "PUT",
                body: publisherData, // Send FormData directly
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to update publisher');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error('Error in updatePublisher:', error);
            throw error;
        }
    };

    // Archive a publisher
    public static archivePublisher = async ( id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/publisher/toggleArchive/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to archive publisher');
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error('Error in archivePublisher:', error);
            throw error;
        }
    };

    // Delete a publisher
    public static deletePublisher = async ( id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/publisher/deletePublisher/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to delete publisher');
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error('Error in deletePublisher:', error);
            throw error;
        }
    };

    // Get a file in specified format (excel, csv, pdf)
    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportPublishers?format=${format}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch file');
            }

            const blob = await resp.blob();
            return blob;
        } catch (error) {
            console.error('Error in getFile:', error);
            throw error;
        }
    };
}
