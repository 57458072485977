import { useState } from 'react';
import styles from "./ArchiveModal.module.css"
import { ReactComponent as CloseIcon } from '../../../images/Shared/CloseIcon.svg';

interface Props {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    header: string;
    parag: string;
    buttonText: string;
    mode: 'archive' | 'delete' | 'publish' | 'activate';
    setIsOpen: (value: boolean) => void;
}

function ArchiveModal({
    isOpen,
    onConfirm,
    onCancel,
    header,
    parag,
    mode,
    buttonText,
    setIsOpen
}: Props) {
    const [loading, setLoading] = useState(false);

    if (!isOpen) {
        return null;
    }

    const handleConfirm = async (e: React.MouseEvent) => {
        e.stopPropagation();
        setLoading(true);
        await onConfirm();
        setLoading(false);
    };

    return (
        <div className={styles.background} onClick={() => setIsOpen(false)}>
            <div className={styles.container}>
                <button className={styles.CloseButton} onClick={onCancel}>
                    <CloseIcon className="CloseIcon" />
                </button>
                <div>
                    <h1 className={styles.header}>{header}</h1>
                    <p className={styles.parag}>{parag}</p>
                </div>
                <div className={styles.buttonContainer}>
                    <button onClick={onCancel} className={styles.cancelButton} type="button">No, cancel</button>
                    <button
                        onClick={handleConfirm}
                        className={
                            mode === 'delete'
                                ? styles.confirmDelButton
                                : styles.confirmButton
                        }
                        type="button"
                        disabled={loading}
                    >
                        {loading ? `${buttonText}...` : buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ArchiveModal;
