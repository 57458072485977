import React, { useState } from 'react';
import styles from './RadioButton.module.css';

interface RadioButtonProps {
  name: string;
  selectedValue: string;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ name, selectedValue, onChange }) => {
  return (
    <div className={styles.radioGroup}>
      <label className={styles.radioLabel}>
        <input
          type="radio"
          name={name}
          value="on use"
          checked={selectedValue === 'on use'}
          onChange={() => onChange('on use')}
          className={styles.radioInput}
        />
        <span className={styles.customRadio}></span>
        On Use
      </label>
      <label className={styles.radioLabel}>
        <input
          type="radio"
          name={name}
          value="fixed"
          checked={selectedValue === 'fixed'}
          onChange={() => onChange('fixed')}
          className={styles.radioInput}
        />
        <span className={styles.customRadio}></span>
        Fixed
      </label>
    </div>
  );
};

const RadioButtonComponent: React.FC<{selectedValue:string,onChange:(value:string)=>void}> = ({selectedValue,onChange}) => {
  
  return (
    <RadioButton
      name="usageType"
      selectedValue={selectedValue}
      onChange={onChange}
    />
  );
};

export default RadioButtonComponent;
