import React, { useState } from 'react';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import styles from './Table.module.css';
import { useNavigate } from 'react-router-dom';
 
interface TableColumn {
  title: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
 }

const Table: React.FC<TableProps> = ({ columns, data}) => {
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const navigate=useNavigate();
  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const isDateColumn = (columnTitle: string) => {
    return columnTitle.toLowerCase().includes('date');
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortColumn) {
      if (isDateColumn(sortColumn)) {
        const dateA = new Date(a[sortColumn]);
        const dateB = new Date(b[sortColumn]);
        return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      } else if (typeof a[sortColumn] === 'number' && typeof b[sortColumn] === 'number') {
        return sortDirection === 'asc' ? a[sortColumn] - b[sortColumn] : b[sortColumn] - a[sortColumn];
      } else {
        const aValue = a[sortColumn].toString().toLowerCase();
        const bValue = b[sortColumn].toString().toLowerCase();
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      }
    }
    return 0;
  });

  const formatDate = (oldDate:string): string => {
    if(!oldDate){
        return " "
    }
    const date = new Date(oldDate);
    const formattedDate = date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    });
    return formattedDate;
  }

  const handleViewCodes=(batchId:string)=>{
    navigate(`/accesscodes/${batchId}`)
  }

  const onEdit=(batchId:string)=>{
    navigate(`/batches/edit/${batchId}`)
  }
  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {columns.map((column, index) => (
            <div key={index} className={styles.tableHeaderCell}>
              {column.title !== 'Phone Nb' && column.title !== 'Logo' && (
                <ArrowIcon
                  className={`${styles.arrowIcon} ${sortColumn === column.title ? (sortDirection === 'asc' ? styles.asc : styles.desc) : ''}`}
                  onClick={() => handleSort(column.title)}
                />
              )}
              {column.title}
            </div>
          ))}
        </div>
        <div className={styles.tableBody}>
          {sortedData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className={styles.tableRow}>
                {columns.map((column, colIndex) => (
                  colIndex!==columns.length-1?
                    (
                      <div key={colIndex} className={styles.tableCell}>
                        {column.title === 'Activation Date' && row[column.title] ? (
                          formatDate(row[column.title])
                        ) : (
                          row[column.title] || <i>null</i>
                        )}
                      </div>
                    ):
                    (
                      <div className={styles.tableCell} key={colIndex}>
                        
                        <div key={colIndex} className={styles.tableCell}>
                          {column.title === 'Activation Date' && row[column.title] ? (
                            formatDate(row[column.title])
                          ) : (
                            row[column.title] || <i>null</i>
                          )}
                        </div>
                        <div className={styles.detailsContainer}>
                        <DetailsIcon
                          className={styles.detailsIcon}
                          onClick={() => toggleDropdown(rowIndex)}
                        />
                        {dropdownOpen === rowIndex && (
                          <div className={styles.dropdownMenu}>
                            <div
                              className={styles.dropdownItem}
                              onClick={() => onEdit(row.batchId)}
                            >
                              Edit
                            </div>
                            <div className={`${styles.dropdownItem}`} onClick={()=>handleViewCodes(row.batchId)}>
                              View Code
                            </div>
                            <div className={`${styles.dropdownItem} ${styles.delete}`}>
                              Delete
                            </div>
                          </div>
                        )}
                      </div>            
                    </div>
                  )
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Table;
