import React, { useContext, useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ReactComponent as MainLogo } from '../../images/Login/MainLogo.svg';
import styles from './Login.module.css';
import { browserLocalPersistence, browserSessionPersistence, getAuth, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../../firebase/firebaseConfig";
import { UserService } from '../../services/UserService';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { UserDTO } from '../../dto/UserDTO';

const Login: React.FC<any> = ({ setLoggedIn }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const auth = getAuth(app);
  const [loading, setLoading] = useState(false);

   const [rememberMeChecked, setRememberMeChecked] = useState(false);

  const { setUser,setRememberMe,rememberMe } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogin = async () => {

    setLoading(true);
    try {

      const persistence = rememberMeChecked ? browserLocalPersistence : browserSessionPersistence;
      await setPersistence(auth, persistence);

      const userCredential = await signInWithEmailAndPassword(auth, email.trim(), password);
      const user = userCredential.user;
      const token = await user.getIdToken(true);
      const success=await getUser();
      if(success){
        navigate('/dashboard')
      }

      

    } catch (error) {
      showErrorToast('Wrong email or password')
      console.error("Error signing innnnn:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const getUser = async () => {
    try {
      const userInfo:UserDTO = await UserService.getUser();
  
      const authorizedRoles = ['admin', 'editor', 'sales'];
      if (userInfo && authorizedRoles.includes(userInfo.role) && userInfo.active) {
          setUser(userInfo);
        setLoggedIn(true);
        return true
      } else {        
        await auth.signOut()
        setUser(null); 
        setLoggedIn(false);
        localStorage.clear()
        sessionStorage.clear()
        showErrorToast('User does not have access');        
        return false

      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      showErrorToast('Failed to fetch user data.'); 
      return false
    }
  };
  
  const handleForgotPassword = async () => {
    if (!email) {
      showErrorToast('Please enter your email address.');
      return;
    }
  
    try {
    
      await sendPasswordResetEmail(auth, email.trim());
      showSuccessToast('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error("Error sending password reset email:", error);
      showErrorToast('Failed to send password reset email.');
    }
  };
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleRememberMeChange = () => {
    console.log('old values',rememberMeChecked,rememberMe)
    setRememberMeChecked(!rememberMeChecked);
    setRememberMe(!rememberMeChecked);  
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <MainLogo className={styles.logo} style={{margin:'auto', marginBottom:'32px'}} />
        <h1 className={styles.title}>Login</h1>

        <div className={styles.underTitle}>Email Address</div>
        <input
          className={styles.loginInput}
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className={styles.underTitle}>Password</div>
        <TextField
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          margin='none'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div className={styles.row}>
          <FormControlLabel
            control={
              <Checkbox
                name="remember"
                sx={{ color: '#000000' }}
                checked={rememberMeChecked}
                onChange={handleRememberMeChange}
              />
            }
            label={<b style={{ color: '#000000' }}>Remember Me</b>}
          />
            <a
              href="#"
              className={styles.forgotPassword}
              onClick={(e) => {
                e.preventDefault();
                handleForgotPassword();
              }}
            >
              Forget password?
            </a>
        </div>

        <Button
          variant="contained"
          className={styles.loginButton}
          fullWidth
          style={{ backgroundColor: '#00726B', color: 'white', marginBlock: '20px', height: 50 }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Login'}
        </Button>
      </div>
    </div>
  );
};

export default Login;
