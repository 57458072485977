import React from 'react';
import './CustomModal.css';
import { ReactComponent as CloseIcon } from '../../images/Shared/CloseIcon.svg';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onClose, onSave }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.valueAsNumber < 0) {
      e.target.value = '0';
    }
  };

  if (!isOpen) return null;

  return (
    <div className="ModalOverlay">
      <div className="ModalContent">
        <button className="CloseButton" onClick={onClose}>
          <CloseIcon className="CloseIcon" />
        </button>
        <div className="ModalHeader">
          <span className="ModalTitle">Modifications</span>
        </div>
        <div className="ModalBody">
          <p className="ModalSubtitle">Any Changes in this page will alter all users of the platform</p>
          <label htmlFor="deviceCount" className="DeviceCountLabel">Device Count</label>
          <input 
            type="number" 
            id="deviceCount" 
            className="DeviceCountInput" 
            defaultValue="2" 
            min="0" 
            onInput={handleInputChange} 
          />
        </div>
        <div className="ModalFooter">
          <button className="CancelButton" onClick={onClose}>No, cancel</button>
          <button className="SaveButton" onClick={onSave}>Yes, Save</button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
