import React, { useContext, useEffect, useState } from 'react'
import styles from './BookAddModal.module.css'

import { PublisherResponseDTO, PublishersDTO } from '../../dto/PublishersDTO';
import { PublishersService } from '../../services/PublishersService';
import DropdownSelector from '../../components/Shared/Textfields/DropdownSelector';
import { ReactComponent as CloseIcon } from '../../images/Shared/CloseIcon.svg';

import { BooksDTO, BooksResponseDTO, Option } from '../../dto/BooksDTO';
import { BooksService } from '../../services/BooksService';
import { BatchesService } from '../../services/BatchesService';
import { AccessCodeDTO, BatchDTO } from '../../dto/BatchesDTO';
import { UserService } from '../../services/UserService';
import { UserContext } from '../../UserContext';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { CircularProgress } from '@mui/material';

const  BookAddModal:React.FC<{setShowModal:(value:boolean)=>void,userId:string}>=({setShowModal,userId})=> {

 
    const [selectedPublisher, setSelectedPublisher] = useState<Option | null>(null);
    const [publisherOptions, setPublisherOptions] = useState<Option[]>([]);
    const [isLoadingPublishers, setIsLoadingPublishers] = useState(true); // Loading state for publishers

    const [bookOptions, setBookOptions] = useState<Option[]>([]);
    const [selectedBook, setSelectedBook] = useState<Option | null>(null);
    const [bookData, setBookData] = useState({
        Publisher: '',
        Book: '',
        LicenseType: 'OnUse', 
        Days: 365,
        StartDate: '',
        EndDate: ''
    });
    const [isLoadingBooks, setIsLoadingBooks] = useState(false);  

    const [batchOptions, setBatchOptions] = useState<Option[]>([]);
    const [selectedBatch, setSelectedBatch] = useState<Option | null>(null);
    const [isLoadingBatches, setIsLoadingBatches] = useState(false); 


    const [accessCodeOptions, setAccessCodeOptions] = useState<Option[]>([]);
    const [selectedAccessCode, setSelectedAccessCode] = useState<Option | null>(null);
    const [isLoadingAccessCodes, setIsLoadingAccessCodes] = useState(false); 

    const [buttonLoading,setButtonLoading]=useState(false)

    const handlePublisherChange = (selected: Option|Option[]) => {
        const selectedOption=selected as Option;
        setSelectedPublisher(selectedOption);
        setSelectedBook(null)
        setSelectedBatch(null)
        setSelectedAccessCode(null)
        setBookData({ ...bookData, Publisher: selectedOption.value });
    };

    const handleBookChange = (selected: Option | Option[]) => {
        const selectedOption = selected as Option;
        setSelectedBook(selectedOption);
        setSelectedBatch(null)
        setSelectedAccessCode(null)
        setBookData({ ...bookData, Book: selectedOption.value });
    };

    const handleLicenseTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBookData({ ...bookData, LicenseType: event.target.value });
    };

    const handleBatchChange = (selected: Option | Option[]) => {
        const selectedOption = selected as Option;
        setSelectedBatch(selectedOption);
        console.log(selectedOption.accessCodes)
        setAccessCodeOptions(selectedOption.accessCodes!)   
    };

    const handleAccessCodeChange = (selected: Option | Option[]) => {
        const selectedOption = selected as Option;
        setSelectedAccessCode(selectedOption);
    };

    const handleSubmit=async()=>{
        try {
            setButtonLoading(true)
           
            const response=await UserService.addBook(selectedAccessCode!.label,selectedBook!.value,userId )
            showSuccessToast(response)
            setShowModal(false)
        } catch (error) {
            showErrorToast('Error while adding book.')
            console.error('error while adding book for user',error);
        }finally{
            setButtonLoading(false)
        }
    }

    //fetch publisher data
    useEffect(() => {
        const fetchPublisherData = async () => {
            try {
                setIsLoadingPublishers(true); 

                const data:PublisherResponseDTO = await PublishersService.getPublishers( false, 10, 1, '');

                const options = data.publishers.map((publisher: PublishersDTO) => ({
                    label: publisher.fullname,
                    value: publisher._id
                }));
                setPublisherOptions(options);
          
            } catch (error) {
                showErrorToast('Error fetching publishers.')
                console.error('Error fetching publisher data:', error);
            }finally{
                setIsLoadingPublishers(false)
            }
        };

        fetchPublisherData();
    }, []);

    //fetch books for publisher
    useEffect(() => {
        const fetchBooksForPublisher = async () => {
            if (selectedPublisher) {
                try {
                    
                    setIsLoadingBooks(true)
                    
                    const books = await BooksService.getBooksByPublisher(10000,1,null,true,selectedPublisher.value );
                    const bookOptions = books.map((book: BooksDTO) => ({
                        label: `${book.bookTitle} | ${book.grades?.map((grade: any) => grade.engName).join(' - ')} | ${book.category?.engName||""}`,
                        value: book._id
                    }));
                    setBookOptions(bookOptions);
                    
                } catch (error) {
                    showErrorToast('Error fetching books for publisher.')

                    console.error('Error fetching books for publisher:', error);
                }finally{
                    setIsLoadingBooks(false)
                }

            }
        };

        fetchBooksForPublisher();
    }, [selectedPublisher]);

    //fetch batch by book id
    useEffect(() => {
        const fetchBatchesForBook = async () => {
            if (selectedBook) {
                try {
                     setIsLoadingBatches(true)
                    setIsLoadingAccessCodes(true)
                         const batches :BatchDTO[]= await BatchesService.getBatchesByBookId( selectedBook.value); 
                        const batchOptions = batches.map((batch: BatchDTO) => ({
                            label: batch.title, 
                            value: batch._id,
                            accessCodes: batch.accessCodes
                            .filter((code: AccessCodeDTO) => !code.activated)
                            .map((code: AccessCodeDTO) => ({
                              label: code.code,
                              value: code._id
                            }))
                          
                        }));
                        
                        setBatchOptions(batchOptions);
                    
                } catch (error) {
                    showErrorToast('Error fetching batches for book.')

                    console.error('Error fetching batches for book:', error);
                }finally{
                    setIsLoadingBatches(false)
                    setIsLoadingAccessCodes(false)
                }
            }   
        };

        fetchBatchesForBook();
    }, [selectedBook]);

  return (
    <div className={styles.background}>
        <div className={styles.body}>
            <button className={styles.xButton} onClick={()=>setShowModal(false)} type='button'>
                <CloseIcon/>
            </button>
            <section className={styles.headerContainer}>
                <p className={styles.header}>Add Book</p>
                <p className={styles.headersText}>Select a book to manually give access to </p>
            </section>

            <div className={styles.form}>
                <div>
                    <DropdownSelector 
                        label="Publisher"
                        options={publisherOptions} 
                        selectedOptions={selectedPublisher} 
                        onChange={handlePublisherChange} 
                        multiSelect={false} 
                        isLoading={isLoadingPublishers}
                    />
                </div>
                <div>
                    <DropdownSelector
                        label="Book"
                        options={bookOptions}
                        selectedOptions={selectedBook}
                        onChange={handleBookChange}
                        multiSelect={false}
                        disabled={selectedPublisher===null}
                        placeholder={selectedPublisher===null?"Please select a publisher first":"Search..."}
                        isLoading={isLoadingBooks}

                    />
                </div>
                <div>
                    <DropdownSelector
                        label="Batch"
                        options={batchOptions}
                        selectedOptions={selectedBatch}
                        onChange={handleBatchChange}
                        multiSelect={false}
                        disabled={selectedPublisher===null || selectedBook===null}
                        placeholder={selectedBook===null?"Please select a book first":"Search..."}
                        isLoading={isLoadingBatches}

                    />
                </div>
                <div>
                    <DropdownSelector
                        label="Access Code"
                        options={accessCodeOptions}
                        selectedOptions={selectedAccessCode}
                        onChange={handleAccessCodeChange}
                        multiSelect={false}
                        disabled={selectedPublisher===null || selectedBook===null || selectedBatch===null}
                        placeholder={selectedBatch===null?"Please select a book first":"Search..."}
                        isLoading={isLoadingAccessCodes}

                        
                    />
                </div>
                {!accessCodeOptions?<>  
                    <div>
                    <label style={{fontWeight:600,fontSize:'20px'}}>License Type</label>
                    <div className={styles.radioButtonContainer}>
                        <div className={styles.radioButtonInput}>
                            <input
                                type="radio"
                                value="Fixed"
                                checked={bookData.LicenseType === 'Fixed'}
                                onChange={handleLicenseTypeChange}
                                style={{backgroundColor:'rgba(0, 114, 107, 1)'}}
                            />
                            <label style={{margin:0,fontWeight:400,fontSize:'20px'}}>Fixed</label>
                        </div>
                        <div className={styles.radioButtonInput}>
                            <input
                                type="radio"
                                value="OnUse"
                                checked={bookData.LicenseType === 'OnUse'}
                                onChange={handleLicenseTypeChange}
                            />
                            <label style={{margin:0,fontWeight:400,fontSize:'20px'}}>On Use</label>
                        </div>
                    </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.field}>
                            <label htmlFor="days">Days</label>
                            <input
                                type="number"
                                id="days"
                                value={bookData.Days}
                                onChange={(e) => setBookData({ ...bookData, Days: parseInt(e.target.value) })}
                                style={{width:'100%'}}
                            />
                        </div>  
                        <div className={styles.field}>
                            <label htmlFor="startDate">Starting Date</label>
                            <input
                                type="date"
                                id="startDate"
                                value={bookData.StartDate}
                                onChange={(e) => setBookData({ ...bookData, StartDate: e.target.value })}
                                style={{width:'100%'}}
                            />
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="endDate">End Date</label>
                            <input
                                type="date"
                                id="endDate"
                                value={bookData.EndDate}
                                onChange={(e) => setBookData({ ...bookData, EndDate: e.target.value })}
                                style={{width:'100%'}}
                            />
                        </div>  
                    </div>
                </>:<></>}
                <div className={styles.buttonContainer}>
                    <button type="button" className={styles.cancelButton} onClick={()=>setShowModal(false)} >No, Cancel</button>
                    <button type="button" className={styles.saveButton} onClick={handleSubmit} disabled={buttonLoading}>{buttonLoading?<CircularProgress/>:"Yes, Save"}</button>
                </div>
            </div>

        </div>
    </div>
  )
}

export default BookAddModal