import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class CategoriesService {
    public static getCategories = async ( page: number, limit: number, archived: boolean, name: string): Promise<any> => {
        try {
            const params = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                archived: String(archived),
                ...(name && { name }),
            });

            const resp = await FetchAuth(`${endpoint}/category/getCategoriesAdmin?${params.toString()}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to fetch categories");
            }

            return await resp.json();
        } catch (error) {
            console.error("Error in getCategories:", error);
            throw error;
        }
    }

    public static getCategoryById = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/${_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to fetch category by ID");
            }

            return await resp.json();
        } catch (error) {
            console.error("Error in getCategoryById:", error);
            throw error;
        }
    };

    public static createCategory = async ( categoryData: FormData): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/create`, {
                method: "POST",

                body: categoryData
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to create category");
            }

            return await resp.json();
        } catch (error) {
            console.error("Error in createCategory:", error);
            throw error;
        }
    };

    public static updateCategory = async ( _id: string, formData: FormData): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/${_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: formData
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to update category");
            }

            return await resp.json();
        } catch (error) {
            console.error("Error in updateCategory:", error);
            throw error;
        }
    };

    public static toggleActivate = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/toggleArchiveStatus/${_id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to toggle category activation status");
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error("Error in toggleActivate:", error);
            throw error;
        }
    };

    public static deleteCategory = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/delete/${_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to delete category");
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error("Error in deleteCategory:", error);
            throw error;
        }
    };

    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportCategories?format=${format}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to export categories file");
            }

            return await resp.blob();
        } catch (error) {
            console.error("Error in getFile:", error);
            throw error;
        }
    };
}
