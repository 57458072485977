import React, { useState, useEffect } from 'react';
import AddButton from '../../components/Shared/AddButton/AddButton';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import CustomModal from '../../components/Users/CustomModal';
import { ArchiveResponse, UserDTO, UserResponseDTO } from '../../dto/UserDTO';
import { UserService } from '../../services/UserService';
import { Link, useNavigate } from 'react-router-dom';
import Table from '../../components/Shared/Table/CategTable';
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';
import styles from './Users.module.css'; 
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { SelectChangeEvent } from '@mui/material';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';


const Users: React.FC = () => {
    const columns = [
        { title: 'ID' },
        { title: 'Name' },
        { title: 'Email Address' },
        { title: 'Role' },
        { title: 'Books Owned' },
        { title: 'Device Count' },
        { title: 'Created Date' },
    ];

    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(0);  
    const [currentPage, setCurrentPage] = useState(1); 

    const [users, setUsers] = useState<UserDTO[]>([]);
    const [total,setTotal]=useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [mode, setMode] = useState<'activate' | 'delete'>('activate'); 
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [loading,setLoading]=useState<boolean>(true)
    const [searchTerm,setSearchTerm] = useState<string>("");

    const handleSearchChange = (value: string) => {
        setLoading(true)
        setCurrentPage(1)
        setSearchTerm(value); 
    };

    const [pageSize, setPageSize] = useState(10);
    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setCurrentPage(1)
        setPageSize(Number(event.target.value));
    };

    const handleActiveButton = (value:number)=>{
        setLoading(true)
        setCurrentPage(1)
        setActiveButton(value)
    }

    const handleConfirmAction = async (): Promise<void> => {
        if (selectedUserId) {
       
            try {
                if (mode === 'activate') {
                    const response:ArchiveResponse=await UserService.toggleActive( selectedUserId);
                    showSuccessToast(response.message)
                } else if (mode === 'delete') {
                    console.log('before')
                    await UserService.deleteUser( selectedUserId).then((res)=>{
                        if(res.ok)    {
                            showSuccessToast('User deleted successfully!')
                        }                    
                    }).catch((err)=>{
                        showErrorToast('Error deleting user.')
                    })

                    
                }
                setUsers(users.filter(user => user._id !== selectedUserId));
                setSelectedUserId(null);
            } catch (error) {
                console.error(`Error ${mode === 'activate' ? 'Activating' : 'deleting'} user:`, error);
                showErrorToast(`Error ${mode === 'activate' ? 'toggling archive' : 'deleting'} user`)
            }
            
        }
        setShowModal(false);
    };

    const handleCancelAction = (): void => {
        setSelectedUserId(null);
        setShowModal(false);
    };

    const handleArchiveClick = (id: string): void => {
        setSelectedUserId(id);
        setMode('activate'); 
        setShowModal(true);
    };

    const handleDeleteClick = (id:string): void => {
        setSelectedUserId(id);
        setMode('delete');
        setShowModal(true);
    };

    //fetch users
    useEffect(() => {
        const fetchUsersData = async () => {
            try {
     
                const active=activeButton===0?true:false;
                const data:UserResponseDTO = await UserService.getAllUsers(currentPage,pageSize,active,searchTerm);
                if(data && data.users && Array.isArray(data.users)) {
                    setUsers(data.users);
                    setTotal(data?.total || 0);
                }
            
             
            } catch (error) {
                showErrorToast('Error fetching users.')
                console.error('Error fetching user:', error);
            }finally{
                setLoading(false)
            }
        };

        fetchUsersData();
    }, [activeButton,currentPage,pageSize,searchTerm]);

    const handleEditClick = (index: number): void => {
        const user = users[index];
        if (user) {
            navigate(`/users/edit/${user._id}`);
        }
    };

    const handleViewClick = (index: number): void => {
        const user = users[index];
        if (user) {
            navigate(`/users/view/${user._id}`);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setLoading(true)
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const handleSaveModal = () => {
      setIsModalOpen(false);
    };

    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    const label =[
        {
            label_name: "Active",
            index: 0
        },
        {
            label_name: "Deactivated",
            index: 1
        }
    ]

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleActiveButton} />
                <div className='flex content-evenly items-center'>
                    {/* <div className={styles.ModificationsText} onClick={handleOpenModal}>Modifications</div> */}
                    <CustomModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveModal} />
                    <Link to="/users/add" className={styles.link}>
                        <AddButton label="ADD USER" />
                    </Link>
                    <ExportButton label="Export" exportType='users' /> 
                </div>
            </div>
            <div className={styles.content2}>
                <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
                <PaginationInfo totalItems={total} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}/>
            </div>
            <Table
                columns={columns}
                data={Array.isArray(users) ?users.map((user) => ({
                    ID: user._id,
                    _id:user._id,
                    Name: user.fullname,
                    'Email Address': user.email,
                    Role: user.role,
                    'Books Owned': user.myLibrary.length.toString(),  
                    'Device Count':user.maxDevices,
                    'Created Date': formatDate(user.dateCreated),  
                    user:true,
                    'active':user.active
                })):[]}
                onEdit={handleEditClick}
                onView={handleViewClick}
                onDelete={handleDeleteClick}
                onArchive={handleArchiveClick}
                loading={loading}
            />

            <div className={styles.content3}>
                <Pagination3 totalPages={Math.ceil(total / pageSize)} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>

            <ArchiveModal
            setIsOpen={setShowModal}

                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode === 'activate' ? activeButton===0?'Archive':"Unarchive" : 'Delete'} this user?`}
                parag={`Sure you want to ${mode === 'activate' ? activeButton===0?'Archive':"Unarchive" : 'Delete'} this user?`}
                mode={mode} 
                buttonText={mode==='activate' ? activeButton===0?'Yes, archive':"Yes, unarchive" : 'Yes, delete'}

            />
        </div>
    );
}

export default Users;
