import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class DashboardServices {
    public static getDashboardData = async (): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/getMain`, {
                method: "GET",
                headers: {
                    'Content-Type': "application/json",
                }
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Failed to fetch dashboard data");
            }

            const data = await resp.json();
            return data.data;
        } catch (error) {
            console.error("Error in getDashboardData:", error);
            throw error;
        }
    }
}
