import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { ReactComponent as Arrow } from '../../../images/Shared/Arrow.svg'; 
import { PublishersService } from '../../../services/PublishersService';
import { SchoolsService } from '../../../services/SchoolsService';
import { UserService } from '../../../services/UserService';
import { GradesService } from '../../../services/GradesService';
import { BatchesService } from '../../../services/BatchesService';
import { CategoriesService } from '../../../services/CategoriesService';
import { BooksService } from '../../../services/BooksService';

interface ExportButtonProps {
  label: string;
  exportType:"publishers"|"schools"|"books"|"users"|"batches"|"announcements"|"categories"|"grades"|'accessCodes';
}

const ExportButtonContainer = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  border: '2px solid #00726B',
  width: '127px',
  height: '48px',
  borderRadius: '5px',
  padding: '8px, 16px, 8px, 16px',
  cursor: 'pointer',
  marginLeft: '16px', 
  position:'relative'
});
const ExportButtonLabel = styled(Box)({
  fontSize:14,
  fontWeight:500,
  fontFamily: 'Rubik, sans-serif', 
  marginRight: '10px',
  color: '#00726B', 
});
const DropDown = styled(Box)<{ open: boolean }>(({ open }) => ({
  position: 'absolute',
  width:'inherit',
  top: '120%',
  right: '0',
  backgroundColor: '#fff',
  border: '1px solid #E5E5E5',
  borderRadius: '10px',
  zIndex: 1,
  display: open ? 'block' : 'none', 
}));
const DropDownItem =styled(Box)({
  textAlign: 'left',
  fontSize:14,
  color:'#212121',
  padding: '8px 16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f2f2f2',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  '&:last-of-type"': {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
})

const ExportButton: React.FC<ExportButtonProps> = ({ label,exportType}) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!open); 
  };
  const handleDownloadFile = async (format: 'excel' | 'pdf' | 'csv') => {

    let blob;
    const link = document.createElement('a');
    let fileName = ''; 
  
    
    if (exportType === 'publishers') {
      blob = await PublishersService.getFile(format);
      fileName = 'publishers'
    } else if (exportType === 'schools') {
      blob = await SchoolsService.getFile( format);
      fileName = 'schools'; 
    }else if(exportType === 'users'){
      blob = await UserService.getFile(format);
      fileName = 'users'; 
    }else if(exportType ==='grades'){
      blob = await GradesService.getFile( format);
      fileName = 'grades'; 
    }else if(exportType ==='batches'){
      blob = await BatchesService.getFile( format);
      fileName = 'batches'; 
    }else if(exportType==='categories'){
      blob = await CategoriesService.getFile( format);
      fileName = 'categories'; 
    }else if(exportType==='books'){
      blob = await BooksService.getFile( format);
      fileName = 'books'; 
    }else if(exportType==="accessCodes"){
      blob = await BooksService.getFileAccessCode( format);
      fileName = 'AccessCodes'; 
    }


  
    
    if (!blob) {
      console.error('blob is empty');
      return;
    }
  
    
    const url = window.URL.createObjectURL(blob);
    link.href = url;
  
    
    link.setAttribute('download', `${fileName}.${format === 'excel' ? 'xlsx' : format}`);
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  


  return (
    <ExportButtonContainer  onClick={toggleDropdown}>
      <ExportButtonLabel>{label}</ExportButtonLabel>
      <Arrow />

      <DropDown open={open}>
          <DropDownItem onClick={()=>handleDownloadFile("excel")}>
            Excel
          </DropDownItem>
          <DropDownItem onClick={()=>handleDownloadFile("csv")}>
            CSV
          </DropDownItem>
          <DropDownItem onClick={()=>handleDownloadFile("pdf")}>
            PDF
          </DropDownItem>
      </DropDown>
    </ExportButtonContainer>
  );
};

export default ExportButton;
