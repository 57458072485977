    import { toast } from 'react-toastify';
    import { ReactComponent as CloseIcon } from '../../../images/Shared/CloseIcon.svg';
    import styles from './toastHelper.module.css'

    export const showSuccessToast = (message: string) => {
        toast.success(
        <div className={styles.toastContent}>
            <div className={styles.leftContainer}>
                <div className={styles.toastIcon}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 25.6663C17.2216 25.6663 20.1383 24.3605 22.2496 22.2492C24.3608 20.138 25.6667 17.2213 25.6667 13.9997C25.6667 10.778 24.3608 7.86137 22.2496 5.75009C20.1383 3.63885 17.2216 2.33301 14 2.33301C10.7784 2.33301 7.8617 3.63885 5.75042 5.75009C3.63917 7.86137 2.33333 10.778 2.33333 13.9997C2.33333 17.2213 3.63917 20.138 5.75042 22.2492C7.8617 24.3605 10.7784 25.6663 14 25.6663Z" fill="#CAFFFC" stroke="#066161" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M9.33333 14L12.8333 17.5L19.8333 10.5" stroke="#066161" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className={styles.toastMessage}>{message}</div>
            </div>
            <div className={styles.closeButton}>
                <CloseIcon className="CloseIcon" />
            </div>
        </div>,
        {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon:false,
            closeButton:false,
        }
        );
    };
    

    export const showErrorToast = (message: string) => {
    toast.error( <div className={styles.toastContent}>
        <div className={styles.leftContainer}>
            <div className={styles.toastIconError}>
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.9997 33.6654C25.2213 33.6654 28.138 32.3595 30.2492 30.2483C32.3605 28.137 33.6663 25.2203 33.6663 21.9987C33.6663 18.7771 32.3605 15.8604 30.2492 13.7491C28.138 11.6379 25.2213 10.332 21.9997 10.332C18.778 10.332 15.8614 11.6379 13.7501 13.7491C11.6388 15.8604 10.333 18.7771 10.333 21.9987C10.333 25.2203 11.6388 28.137 13.7501 30.2483C15.8614 32.3595 18.778 33.6654 21.9997 33.6654Z" fill="#FFDBDB" stroke="#BE1E2D" stroke-width="2" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2987 17.2013C18.1565 17.0688 17.9685 16.9967 17.7742 17.0001C17.5799 17.0035 17.3945 17.0823 17.2571 17.2197C17.1197 17.3571 17.041 17.5425 17.0375 17.7368C17.0341 17.9311 17.1062 18.1191 17.2387 18.2613L20.9587 21.9813L17.2387 25.7013C17.165 25.77 17.1059 25.8528 17.0649 25.9448C17.0239 26.0368 17.0019 26.1361 17.0001 26.2368C16.9983 26.3375 17.0169 26.4375 17.0546 26.5309C17.0923 26.6243 17.1485 26.7091 17.2197 26.7803C17.2909 26.8515 17.3757 26.9077 17.4691 26.9454C17.5625 26.9831 17.6625 27.0017 17.7632 26.9999C17.8639 26.9981 17.9632 26.9761 18.0552 26.9351C18.1472 26.8941 18.23 26.835 18.2987 26.7613L22.0187 23.0413L25.7387 26.7613C25.8074 26.835 25.8902 26.8941 25.9822 26.9351C26.0742 26.9761 26.1735 26.9981 26.2742 26.9999C26.3749 27.0017 26.4749 26.9831 26.5683 26.9454C26.6617 26.9077 26.7465 26.8515 26.8177 26.7803C26.889 26.7091 26.9451 26.6243 26.9828 26.5309C27.0206 26.4375 27.0391 26.3375 27.0373 26.2368C27.0355 26.1361 27.0135 26.0368 26.9725 25.9448C26.9315 25.8528 26.8724 25.77 26.7987 25.7013L23.0787 21.9813L26.7987 18.2613C26.9312 18.1191 27.0033 17.9311 26.9999 17.7368C26.9965 17.5425 26.9177 17.3571 26.7803 17.2197C26.6429 17.0823 26.4575 17.0035 26.2632 17.0001C26.0689 16.9967 25.8809 17.0688 25.7387 17.2013L22.0187 20.9213L18.2987 17.2013Z" fill="#BE1E2D"/>
            </svg>


            </div>
            <div className={styles.toastMessage}>{message}</div>
        </div>
        <div className={styles.closeButton}>
            <CloseIcon className="CloseIcon" />
        </div>
    </div>,
    {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon:false,
        closeButton:false,
    }
    );
    };
