import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import styles from './AdminAccountsTable.module.css';
import { useNavigate } from 'react-router-dom';
import { showSuccessToast } from '../Toastify/toastHelper';
import { CircularProgress } from '@mui/material';

interface TableColumn {
  title: string;
  key: string;
  isDate?: boolean;
  isNumeric?: boolean;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  onEdit: (id: string) => void;
  loading:boolean;
  onArchive: (id: string) => void;
  onDelete: (id: string) => void;

}

const AdminAccountsTable: React.FC<TableProps> = ({ columns, data, onEdit,loading,onArchive,onDelete }) => {
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);
  const navigate = useNavigate();
  const dropdownRef=useRef<HTMLDivElement|null>(null)
  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const { key, direction } = sortConfig;
        let aValue = a[key];
        let bValue = b[key];

        if (columns.find(col => col.key === key)?.isDate) {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        } else if (columns.find(col => col.key === key)?.isNumeric) {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig, columns]);

  const selectedRowIndex = useRef<string | null>(null);
  const selectedFullText = useRef<string | undefined>(undefined);

  const handleRowClick = (id: string) => {
    navigate(`/adminaccounts/view/${id}`);
  };
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
    // Return the cleanup function to remove the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const handleMouseUp = () => {
      const textSelected = window.getSelection()?.toString();
      if (textSelected && selectedFullText.current) {
        navigator.clipboard.writeText(selectedFullText.current).then(() => {
          showSuccessToast('Text copied to clipboard');
        });
      } else if (selectedRowIndex.current !== null) {
        handleRowClick(selectedRowIndex.current);
      }

      selectedRowIndex.current = null;
      selectedFullText.current = undefined;
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader} style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}>
          {columns.map((column, index) => (
            <div key={index} className={styles.tableHeaderCell} onClick={() => handleSort(column.key)}>
              <ArrowIcon className={styles.arrowIcon} />
              {column.title}
            </div>
          ))}
        </div>
       {!loading? <div className={styles.tableBody}>
          {sortedData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div
                className={styles.tableRow}
                onMouseDown={() => (selectedRowIndex.current = row._id)}
                style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}
              >
                {columns.map((column, colIndex) => (
                  <div
                    key={colIndex}
                    className={styles.tableCell}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      selectedFullText.current = row[column.key];
                    }}
                  >
                    {column.key === 'role' ? (
                      <span className={`${styles.roleText} ${row.roleClass}`}>{row[column.key]}</span>
                    ) : colIndex !== columns.length - 1 ? (
                      row[column.key]
                    ) : (
                      <div className={styles.lastCol}>
                        {row[column.key]}
                        <div className={styles.actions}>
                          <DetailsIcon
                            className={styles.detailsIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown(rowIndex);
                            }}
                          />
                          {dropdownOpen === rowIndex && (
                            <div className={styles.dropdownMenu} ref={dropdownRef}>
                              <div className={styles.dropdownItem}onClick={(e) => {
                                  e.stopPropagation();
                                  onArchive(row._id);
                                  toggleDropdown(rowIndex)
                                }}>Deactivate</div>
                              <div
                                className={styles.dropdownItem}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onEdit(row._id);
                                  toggleDropdown(rowIndex)
                                }}
                              >
                                Edit
                              </div>
                              <div className={`${styles.dropdownItem} ${styles.delete}`} onClick={(e)=>{e.stopPropagation();onDelete(row._id);toggleDropdown(rowIndex)}}>Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <hr className={styles.rowSeparator} />
            </React.Fragment>
          ))}
        </div>
        :
        <div style={{display:'flex',justifyContent:"center",alignItems:'center',padding:'32px'}}>
          <CircularProgress/>
        </div>

        }
      </div>
    </div>
  );
};

export default AdminAccountsTable;
