import React, { useEffect, useRef, useState } from 'react';
import styles from './DropdownSelector.module.css';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/Arrow2.svg';
import { ReactComponent as XIcon } from '../../../images/Shared/Xicon.svg';
import { CircularProgress } from '@mui/material';

interface Option {
  label: string;
  value: string;
}

interface DropdownSelectorProps {
  label: string;   
  options: Option[];
  selectedOptions: Option[] | Option | null;
  onChange: (selected: Option[] | Option) => void ;
  multiSelect?:boolean;
  disabled?:boolean;
  placeholder?:string
  isLoading?:boolean
}

const DropdownSelector: React.FC<DropdownSelectorProps> = ({  label, options, selectedOptions, onChange, multiSelect = false,disabled,placeholder,isLoading  }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selected=selectedOptions as Option;
  const [searchTerm, setSearchTerm] = useState<string>(selected?.label || '');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const dropDownRef=useRef<HTMLDivElement|null>(null)
  useEffect(() => {
    if (!multiSelect && selectedOptions && (selectedOptions as Option).label) {
      const singleOption = selectedOptions as Option;
      setSearchTerm(singleOption.label);
    } else if (multiSelect && selectedOptions) {
      setSearchTerm(''); 
    }
  }, [selectedOptions, multiSelect]);

  useEffect(()=>{
    const handleClickOutside = (event: MouseEvent) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  },[])
  const toggleDropdown = () => {
    if(disabled){
      return 
    }
    setSearchTerm('')
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option: Option) => {
    if (multiSelect) {
      const currentSelected = selectedOptions as Option[];
      if (currentSelected.some(selected => selected.value === option.value)) {
        onChange(currentSelected.filter(selected => selected.value !== option.value));
      } else {
        onChange([...currentSelected, option]);
      }
      setSearchTerm('')
    } else {
      onChange(option);
      setSearchTerm(option.label)
      setIsOpen(false);
    }
  };  

  const handleRemoveOption = (option: Option) => {
    if (multiSelect) {
      const currentSelected = selectedOptions as Option[];
      onChange(currentSelected.filter(selected => selected.value !== option.value));
    }
  };
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true)
  };

  useEffect(() => {
    setFilteredOptions(
      options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase().trim())
      )
    );
  }, [options, searchTerm]);
  useEffect(() => {
    // Reset search term if selected options change
    if (!multiSelect && selectedOptions && (selectedOptions as Option).label) {
      setSearchTerm((selectedOptions as Option).label);
    } else if (multiSelect && selectedOptions) {
      setSearchTerm(''); // Clear search term for multi-select
    } else {
      setSearchTerm(''); // Clear search term if no option is selected
    }

    // Close dropdown if open
    setIsOpen(false);
  }, [selectedOptions, multiSelect]);

  return (
    <div className={styles.dropdownSelector} ref={dropDownRef}>
      <label className={styles.label}>{label}</label>  
      <div className={styles.dropdownContainer} onClick={toggleDropdown}>
        <div className={styles.selectedOptions}>
        {multiSelect ? (
          <>
            {(selectedOptions as Option[]).map((option, index) => (
              <div key={index} className={styles.option}>
                <XIcon onClick={() => handleRemoveOption(option)} />
                <span>{option.label}</span>
              </div>
            ))}
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchFeild}
              disabled={disabled}
              placeholder={placeholder?placeholder:'Search...'}
            />
          </>
          ) : (
              <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchFeild}
              placeholder={placeholder?placeholder:'Search...'}
              disabled={disabled}
            />  
          )}
        </div>
        <ArrowIcon className={styles.arrowIcon} />
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {!isLoading?(
            Array.isArray(filteredOptions)&&filteredOptions.length>0?
              (filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={styles.menuItem}
                  onClick={() => handleSelectOption(option)}
                >
                  {option.label}
                </div>))
              )
              :<div className={styles.menuItem}>No data found</div>
          ):
          <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
            <CircularProgress/>
          </div>
          }
        </div>
      )}
    </div>
  );
};

export default DropdownSelector;
