const getYearsFrom2024 = (): string[] => {
  const currentYear = new Date().getFullYear();
  const years: string[] = []; 

  for (let year = 2024; year <= currentYear; year++) {
    years.push(String(year)); 
  }

  return years.reverse();
};

export default getYearsFrom2024;
