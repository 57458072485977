  import React, { useContext, useEffect, useState } from 'react';
  import './App.css';
  import Login from './pages/Login/Login';
  import Dashboard from './pages/Dashboard/Dashboard';
  import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
  import Sidebar from './components/Shared/SideBar/SideBar';
  import Header from './components/Shared/Header/Header';
  import Publishers from './pages/Publishers/Publishers';
  import Schools from './pages/Schools/Schools';
  import PublisherForm from './pages/Publishers/PublisherForm';
  import SchoolForm from './pages/Schools/SchoolForm';
  import Books from './pages/Books/Books';
  import Batches from './pages/Batches/Batches';
  import BatchesForm from './pages/Batches/BatchesForm';

  import BookForm from './pages/Books/BookForm';
  import Users from './pages/Users/Users';
  import UserForm from './pages/Users/UserForm';
  import Categories from './pages/Categories/Categories';
  import CategoriesForm from './pages/Categories/CategoriesForm';
  import Grades from './pages/Grades/Grades';
  import GradesForm from './pages/Grades/GradesForm';
  import Announcements from './pages/Announcements/Announcements';
  import AnnouncementsForm from './pages/Announcements/AnnouncementsForm';
  import Reports from './pages/Reports/Reports';
  import AdminAccounts from './pages/AdminAccounts/AdminAccounts';
  import AdminAccountsForm from './pages/AdminAccounts/AdminAccountsForm';
  import { UserContext } from './UserContext';
  import { UserDTO } from './dto/UserDTO';
  import { getAuth, onIdTokenChanged } from 'firebase/auth';
  import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import AccessCodeList from './pages/Batches/AccessCodeList';
import { UserService } from './services/UserService';
import { CircularProgress } from '@mui/material';
import NotAuthorized from './pages/NotAuthorized/NotAuthorized';
import { auth } from './firebase/firebaseConfig';

  const App: React.FC = () => {
    const { user, setUser } = useContext(UserContext);

    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState("Maritta");
    const [isAdmin, setIsAdmin] = useState(true);
    const [isOnline, setIsOnline] = useState(true);
    const [loading,setLoading]=useState(true);

    const {rememberMe}=useContext(UserContext)
    const navigate=useNavigate()
    const location = useLocation();

    const hasAccess = (role: string, path: string) => {
      switch (role) {
        case 'admin':
          return true;
        case 'editor':
          return !['/adminaccounts', '/reports'].includes(path); 
        case 'sales':
          return path === '/reports';
        default:
          return false;
      }
    };
    
    

    const getPageTitle = (path: string) => {

      if (path.startsWith('/publishers/edit/')) {
        return 'Edit Publisher';
      } else if (path.startsWith('/schools/edit/')) {
        return 'Edit School';
      }  else if (path.startsWith('/schools/view/')) {
        return 'School Details';

      }  else if (path.startsWith('/publishers/view/')) {
        return 'Publisher Details';
      } 
      else if (path.startsWith('/books/edit/')) {
        return 'Edit Book';
      }  else if (path.startsWith('/users/edit/')) {
        return 'Edit User';
      }  else if (path.startsWith('/users/view/')) {
        return 'User Details';
      }  else if (path.startsWith('/categories/edit/')) {
        return 'Edit Category';
      } 
      else if (path.startsWith('/grades/edit/')) {
        return 'Edit Grade';
      } 
      else if (path.startsWith('/announcements/edit/')) {
        return 'Edit Announcement';
      } 
      else if (path.startsWith('/announcements/view/')) {
        return 'Announcement Details';
      } 
      else if (path.startsWith('/adminaccounts/edit/')) {
        return 'Edit Account';
      } 
      else if (path.startsWith('/categories/view/')) {
        return 'Category Details';
      } 
      else if (path.startsWith('/grades/view/')) {
        return 'Grade Details';
      } else if (path.startsWith('/accesscodes')) {
        return 'Access Codes List';
      }else if (path.startsWith('/batches/view/')) {
        return 'Batch Details';
      }else if (path.startsWith('/batches/edit/')) {
        return 'Edit Batch';
      }else if (path.startsWith('/books/view/')) {
        return 'Book Details';
      }else if (path.startsWith('/books/edit/')) {
        return 'Book Details';
      }



      switch (path) {
        case '/dashboard':
          return 'Dashboard';

          case '/publishers':
            return 'Publishers';
            case '/publishers/add':
              return 'Create Publisher';
              case '/publishers/view/:id':
                return 'Publisher Details';
            

                  case '/schools':
                      return 'Schools';
                      case '/schools/add':
                        return 'Create School';

                            case '/books':
                            return 'Books';
                            case '/batches':
                              return 'Batches';
                              case '/books/add':
                                return 'Create Book';

                                case '/batches/add':
                                  return 'Create batch';

                        case '/users':
                          return 'Users';
                          case '/users/add':
                            return 'Create User';

                            case '/categories':
                              return 'Categories';
                              case '/categories/add':
                                return 'Create Category';

                                case '/grades':
                                  return 'Grades';
                                  case '/grades/add':
                                    return 'Create Grade';
                                    
                                    case '/announcements':
                                      return 'Announcements';
                                      case '/announcements/add':
                                        return 'Create Announcement';

                                        case '/reports':
                                          return 'Reports';
        
                                          case '/adminaccounts':
                                            return 'Admin Accounts';

                                            case '/adminaccounts/add':
                                        return 'Create Account';
            
        default:
          return '';
      }
    };

    const pageTitle = getPageTitle(location.pathname);

    const handleTokenChange = async () => {
      const auth = getAuth();
  
      onIdTokenChanged(auth, async (firebaseUser) => {
        if (firebaseUser) {
          try {
            setLoading(true);
            const newToken = await firebaseUser.getIdToken(true);
            const response = await UserService.getUser();
            const userInfo: UserDTO = response;
            setUser(userInfo && userInfo._id && userInfo.role ? userInfo : null);
          } catch (error) {
            console.error("Error fetching new token or user info: ", error);
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
          // setToken(null);
          setUser(null);
          sessionStorage.clear()

          localStorage.clear();
        }
      });
    };
  
    useEffect(() => {
      handleTokenChange();
    }, []);
  
    useEffect(() => {
      if (!loading) {
        if (user) {
          if (!hasAccess(user.role, location.pathname)) {
            navigate('/notauthorized', { replace: true });
          }
        }
      }
    }, [user, location.pathname, navigate, loading]);
  
    return (
      <div className="App">
        <ToastContainer style={{width:'546px',height:'74px', position:'fixed',top:'124px',right:'26px'}}/>
        {
          !loading?
            user ? (
              <div className="main-container">
                <Sidebar role={user?.role || ''}/>
                <div className='content-container'>
                  <div className='header'>
                    <Header pageTitle={pageTitle} username={user?.fullname} role={user?.role} isOnline={isOnline} />
                  </div>
                  <div className="content">
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />

                    {hasAccess(user?.role ?? '', '/publishers') ? (
                      <>
                        <Route path="/publishers" element={<Publishers />} />
                        <Route path="/publishers/add" element={<PublisherForm mode="add" />} />
                        <Route path="/publishers/edit/:id" element={<PublisherForm mode="edit" />} />
                        <Route path="/publishers/view/:id" element={<PublisherForm mode="view" />} />
                      </>
                    ):
                    <Route path="/publishers/*" element={<NotAuthorized/>} />

                    }
                    {hasAccess(user?.role ?? '', '/schools') ? (
                      <>
                        <Route path="/schools" element={<Schools />} />
                        <Route path="/schools/add" element={<SchoolForm mode="add" />} />
                        <Route path="/schools/edit/:id" element={<SchoolForm mode="edit" />} />
                        <Route path="/schools/view/:id" element={<SchoolForm mode="view" />} />
                      </>
                    ):
                    <Route path="/schools/*" element={<NotAuthorized/>} />
                    }
                    {hasAccess(user?.role ?? '', '/books') ? (
                      <>
                        <Route path="/books" element={<Books />} />
                        <Route path="/books/add" element={<BookForm mode="add" />} />
                        <Route path="/books/edit/:id" element={<BookForm mode="edit" />} />
                        <Route path="/books/view/:id" element={<BookForm mode="view" />} />
                      </>
                    ):
                    <Route path="/books/*" element={<NotAuthorized/>} />
                    }
                    {hasAccess(user?.role ?? '', '/batches') ? (
                      <>
                        <Route path="/batches" element={<Batches />} />
                        <Route path="/batches/add" element={<BatchesForm mode="add" />} />
                        <Route path="/batches/edit/:id" element={<BatchesForm mode="edit" />} />
                        <Route path="/batches/view/:id" element={<BatchesForm mode="view" />} />
                      </>
                    ):
                    <Route path="/batches/*" element={<NotAuthorized/>} />
                    }
                    <Route path="/accesscodes/:id" element={<AccessCodeList />} />
                    {hasAccess(user?.role ?? '', '/users') ?(
                      <>
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/add" element={<UserForm mode="add" />} />
                        <Route path="/users/edit/:id" element={<UserForm mode="edit" />} />
                        <Route path="/users/view/:id" element={<UserForm mode="view" />} />
                      </>
                    ):
                    <Route path="/users/*" element={<NotAuthorized/>} />

                    }
                    {hasAccess(user?.role ?? '', '/categories') ? (
                      <>
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/categories/add" element={<CategoriesForm mode="add" />} />
                        <Route path="/categories/edit/:id" element={<CategoriesForm mode="edit" />} />
                        <Route path="/categories/view/:id" element={<CategoriesForm mode="view" />} />
                      </>
                    ): 
                    <Route path="/categories/*" element={<NotAuthorized/>} />
                    }
                    {hasAccess(user?.role ?? '', '/grades') ? (
                      <>
                        <Route path="/grades" element={<Grades />} />
                        <Route path="/grades/add" element={<GradesForm mode="add" />} />
                        <Route path="/grades/edit/:id" element={<GradesForm mode="edit" />} />
                        <Route path="/grades/view/:id" element={<GradesForm mode="view" />} />
                      </>
                    ):
                    <Route path="/grades/*" element={<NotAuthorized/>} />
                    }
                    {hasAccess(user?.role ?? '', '/announcements') ? (
                      <>
                        <Route path="/announcements" element={<Announcements />} />
                        <Route path="/announcements/add" element={<AnnouncementsForm mode="add" />} />
                        <Route path="/announcements/edit/:id" element={<AnnouncementsForm mode="edit" />} />
                        <Route path="/announcements/view/:id" element={<AnnouncementsForm mode="view" />} />
                      </>
                    ):
                    <Route path="/announcements/*" element={<NotAuthorized/>} />
                    }
                    {hasAccess(user?.role ?? '', '/reports') ? (
                      <Route path="/reports" element={<Reports />} />
                    ):
                    <Route path="/reports/*" element={<NotAuthorized/>} />

                    }
                    {hasAccess(user?.role ?? '', '/adminaccounts') ? (
                      <>
                        <Route path="/adminaccounts" element={<AdminAccounts />} />
                        <Route path="/adminaccounts/add" element={<AdminAccountsForm mode="add" />} />
                        <Route path="/adminaccounts/edit/:id" element={<AdminAccountsForm mode="edit" />} />
                        <Route path="/adminaccounts/view/:id" element={<AdminAccountsForm mode="view" />} />
                      </>
                    ):
                    <Route path="/adminaccounts/*" element={<NotAuthorized/>} />
                    }
                    <Route path="/notauthorized" element={<NotAuthorized/>} />

                  </Routes>
                  </div>
                </div>
              </div>
            ) : (
              <Login setLoggedIn={setLoggedIn}/>
            )
          :
          <div style={{width:'100%',height:'100%',display:'flex',alignItems:"center",justifyContent:'center'}}>
            <CircularProgress/>
          </div>
        }
      </div>
    );
  };

  const AppWrapper: React.FC = () => {

    const [user, setUser] = useState<UserDTO | null>(null);
    const [rememberMe, setRememberMe] = useState<boolean >(false);
  
    return (
      <UserContext.Provider value={{ user, setUser,rememberMe,setRememberMe }}>
        <Router>
          <App />
        </Router>
      </UserContext.Provider>
    );
  };

  export default AppWrapper;
