import styles from './UserTable.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Users } from '../../dto/DashboardDTO';
import React from 'react';
import {  useNavigate } from 'react-router-dom';


const RecentUsersTable: React.FC<{users?:Users[],loading:boolean }> = ({users,loading}) => {
    const navigate=useNavigate()
    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    const handleRowClick=(id:string)=>{
        navigate(`/users/view/${id}`)
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span>Recent Users</span>
            </div>
            <hr className={styles.thinLine} />
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Email Address</th>
                        <th>Books Owned</th>
                        <th>Joining Date</th>
                    </tr>
                </thead>
                <tbody>
                    {!loading?
                        users?users.map((user, index) => (
                            <React.Fragment key={user._id}>
                                <tr className={styles.tableRow} onClick={()=>handleRowClick(user._id)}>
                                <td title={user.fullname}>{user.fullname}</td>
                                <td title={user.email}>{user.email}</td>
                                <td title={String(user.libraryCount)} className={styles.booksOwned}>{user.libraryCount}</td>
                                <td title={formatDate(user.dateCreated)}>{formatDate(user.dateCreated)}</td>
                                </tr>
                                {index < users.length - 1 && (
                                    <tr className={styles.separator}>
                                        <td colSpan={4}>
                                            <hr className={styles.thinLine} />
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        )):(
                            <tr className={styles.loadingtr}>
                                <td className={styles.loadingtd} colSpan={4}>
                                    no data found                            
                                </td>
                            </tr>
                            )
                        :<tr className={styles.loadingtr}>
                            <td className={styles.loadingtd} colSpan={4}>
                                <CircularProgress/>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
};

export default RecentUsersTable;
