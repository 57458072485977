import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface Label {
  label_name: string;
  index: number;
}

interface ToggleButtonProps {
  labels: Label[];
  activeButton: number;
  setActiveButton: (index: number) => void;
}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'totalLabels'
})<{ totalLabels: number }>(({ totalLabels }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  border: '1px solid #e0e0e0',
  borderRadius: '6px',
  width: `calc(${totalLabels} * 133px)`,  
  height: '49px',
  position: 'relative',
  cursor: 'pointer',
}));


const Toggle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'activeIndex' && prop !== 'totalLabels'
})<{ activeIndex: number; totalLabels: number }>(({ activeIndex, totalLabels }) => ({
  position: 'absolute',
  left: `${(activeIndex * 100) / totalLabels}%`,
  width: `${94 / totalLabels}%`,
  marginLeft: '4px',
  height: '39px',
  backgroundColor: '#00726B',
  borderRadius: '6px',
  transition: 'left 0.3s',
}));


const Label = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active: boolean }>(({ active }) => ({
  width: '100%',
  textAlign: 'center',
  fontWeight: 600,
  zIndex: 1,
  color: active ? '#ffffff' : '#829291',
  fontFamily: 'Poppins, sans-serif',
  fontSize: 13,
  cursor: 'pointer',
}));

const ToggleButtons: React.FC<ToggleButtonProps> = ({ labels, activeButton, setActiveButton }) => {

  

  return (
    <Container totalLabels={labels.length}>
      {/* Dynamically set the position of the toggle background */}
      <Toggle activeIndex={activeButton} totalLabels={labels.length} />
      
      {/* Dynamically render labels */}
      {labels.map((label) => (
        <Label
          key={label.index}
          active={activeButton === label.index}
          onClick={() => setActiveButton(label.index)}
        >
          {label.label_name}
        </Label>
      ))}
    </Container>
  );
};

export default ToggleButtons;
