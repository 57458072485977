import React from 'react';
import styles from './Header.module.css';
import { ReactComponent as OnlineIcon } from '../../../images/Shared/UserProfile.svg';

interface HeaderProps {
  pageTitle: string;
  username: string | undefined;
  role: string | undefined;
  isOnline: boolean;
}

const Header: React.FC<HeaderProps> = ({ pageTitle, username, role, isOnline }) => {
  return (
    <div className={styles.header}>
      <div className={styles.pageTitle}>
        {pageTitle}
      </div>
      <div className={styles.userInfo}>
        <div className={styles.userRow}>
          <div className={styles.username}>
            {username}
          </div>
          <div className={styles.onlineStatus}>
            {isOnline ? (
              <span className={styles.onlineDot}>
                <OnlineIcon className={styles.onlineIcon} />
              </span>
            ) : (
              <span className={styles.offlineDot}></span>
            )}
          </div>
        </div>
        <div className={styles.role}>
          {role}
        </div>
      </div>
    </div>
  );
};

export default Header;
