import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class SchoolsService {
    // Get all schools with pagination and search functionality
    public static getSchools = async (
        archived: boolean,
        page: number,
        limit: number,
        searchTerm: string
    ): Promise<any> => {
        try {
            const resp = await FetchAuth(
                `${endpoint}/school/admin/getAllSchools?name=${searchTerm}&archived=${archived}&page=${page}&limit=${limit}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch schools');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in getSchools:", error);
            throw error;
        }
    };

    // Create a new school
    public static createSchool = async (schoolData: any): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/createSchool`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(schoolData),
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to create school');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in createSchool:", error);
            throw error;
        }
    };

    // Get school details by ID
    public static getSchoolById = async (_id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/getSchool/${_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch school details');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in getSchoolById:", error);
            throw error;
        }
    };

    // Update school details
    public static updateSchool = async (_id: string, schoolData: any): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/updateSchool/${_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(schoolData),
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to update school');
            }

            const data = await resp.json();
            return data;
        } catch (error) {
            console.error("Error in updateSchool:", error);
            throw error;
        }
    };

    // Delete a school
    public static deleteSchool = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/deleteSchool/${_id}0`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to delete school');
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error("Error in deleteSchool:", error);
            throw error;
        }
    };

    // Toggle school archive status
    public static toggleArchive = async ( _id: string | null): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/toggleArchiveStatus/${_id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to toggle archive status');
            }

            const data = await resp.json();
            return data.message;
        } catch (error) {
            console.error("Error in toggleArchive:", error);
            throw error;
        }
    };

    // Get file in specified format (excel, csv, pdf)
    public static getFile = async (format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportSchools?format=${format}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || 'Failed to fetch file');
            }

            const blob = await resp.blob();
            return blob;
        } catch (error) {
            console.error("Error in getFile:", error);
            throw error;
        }
    };
}
