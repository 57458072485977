import React, { HtmlHTMLAttributes, useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as DetailsIcon } from '../../../images/Shared/Details.svg';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/ArrowIcon.svg';
import styles from './Table2.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { showSuccessToast } from '../Toastify/toastHelper';
import { UserContext } from '../../../UserContext';

interface TableColumn {
  title: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[]; 
  onEdit: (index: number) => void;
  onView: (index: number) => void;
  onDelete: (id:string) => void; 
  onArchive:(id:string)=>void;
  loading:boolean;
}

const Table: React.FC<TableProps> = ({ columns, data, onEdit, onView, onDelete,onArchive,loading}) => {
  const {user}=useContext(UserContext)
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const dropdownRef =useRef<HTMLDivElement|null>(null)
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  
  const selectedRowIndex = useRef<number | null>(null);
  const selectedFullText = useRef<string | undefined>(undefined);

  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  useEffect(()=>{
    const handleClickOutside=(event:MouseEvent)=>{
      if( dropdownRef.current && !dropdownRef.current.contains(event.target as Node)){
        setDropdownOpen(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return ()=>{
          document.removeEventListener('mousedown', handleClickOutside)
        }
  },[])

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };  

  const parseValue = (value: any) => {
    if (value === null || value === undefined) {
      return null; // Handle null or undefined values
    }
  
    if (typeof value === 'string') {
      // Check if the string is a date
      if (!isNaN(Date.parse(value))) {
        return new Date(value).getTime();
      }
  
      // Handle strings with numbers like "Grade10" or "Item25"
      const numberMatch = value.match(/\d+/);  // Extract numeric part
      if (numberMatch) {
        return parseInt(numberMatch[0], 10);  // Return as a number
      }
  
      // Return lowercase string for text comparisons
      return value.toLowerCase();
    }
  
    // Return the value as-is for numbers, etc.
    return value;
  };
  
  const sortedData = [...data].sort((a, b) => {
    if (sortColumn) {
      const aValue = parseValue(a[sortColumn]);
      const bValue = parseValue(b[sortColumn]);
  
      // Handle null values explicitly
      if (aValue === null && bValue !== null) return sortDirection === 'asc' ? 1 : -1;
      if (aValue !== null && bValue === null) return sortDirection === 'asc' ? -1 : 1;
      if (aValue === null && bValue === null) return 0;
  
      // Handle number comparisons
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }
  
      // Handle string comparisons
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    }
    return 0;
  });

  const handleDelete = (id: string) => {
      onDelete(id);  
  };

  const handleRowClick = (rowIndex: number, fullText?: string) => {
    selectedRowIndex.current = rowIndex;
    selectedFullText.current = fullText;
  };

  useEffect(() => {
    const handleMouseUp = () => {
      const textSelected = window.getSelection()?.toString();
      if (textSelected && selectedFullText.current) {
        navigator.clipboard.writeText(selectedFullText.current).then(() => {
          showSuccessToast('Text copied to clipboard');
        });
      } else{
        if(selectedRowIndex.current!==null)
          onView(selectedRowIndex.current);
      }

      selectedRowIndex.current = null;
      selectedFullText.current = undefined;
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onView]);

  return ( 
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader} style={{gridTemplateColumns:`repeat(${columns.length},1fr)`}}>
          {columns.map((column, index) => (
            <div key={index} className={styles.tableHeaderCell} onClick={() => handleSort(column.title)}>
              {column.title !== 'Book Cover' && column.title !== 'Logo' && column.title !== 'Active Icon' && column.title !== 'Default Icon' && (
                <ArrowIcon
                  className={`${styles.arrowIcon} ${sortColumn === column.title ? (sortDirection === 'asc' ? styles.asc : styles.desc) : ''}`}
                />
              )}
              {column.title}
            </div>
          ))}
        </div>
        <div className={styles.tableBody}>
        {loading ? (
            <div className='text-center mt-8'>
              <CircularProgress />
            </div>
            ) : sortedData.length > 0 && Array.isArray(sortedData) ?   (
                sortedData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className={styles.tableRow} onMouseDown={(event) => {event.stopPropagation();handleRowClick(rowIndex)}} style={{gridTemplateColumns:`repeat(${columns.length},1fr)`}}>
                {columns.map((column, colIndex) => (
                  <div key={colIndex} className={styles.tableCell} onMouseDown={(event) => {event.stopPropagation();handleRowClick(rowIndex,row[column.title])}}>
                    {['Book Cover', 'Logo', 'Active Icon', 'Default Icon','Cover'].includes(column.title) ? (
                      <img src={row[column.title]} alt={column.title} className={styles.coverImage} />
                    ) : (colIndex !== columns.length - 1?(
                        <span onClick={(event)=>{event.stopPropagation();handleRowClick(rowIndex,row[column.title])}} title={row[column.title] || 'null'} >
                          {row[column.title] || <i>null</i>}
                        </span>
                      ): 
                      (
                      <div style={{display:'flex', alignItems:"center",justifyContent:"space-between"}}>
                        <span onMouseDown={(event)=>{event.stopPropagation();handleRowClick(rowIndex,row[column.title])}}>
                          {row[column.title] || <i>null</i>}
                        </span>
                          <div className='relative'>
                            <div ref={dropdownRef}>
                            <DetailsIcon className={styles.detailsIcon} onMouseDown={(event) => { event.stopPropagation(); toggleDropdown(rowIndex); }} />
                            </div>
                            {dropdownOpen === rowIndex && (
                              <div className={styles.dropdownMenu} >

                                {user?.role==='admin'?
                                  <div className={styles.dropdownItem} onMouseDown={(event)=>{event.stopPropagation(); onArchive(row._id);toggleDropdown(rowIndex)}}>{row.user!==true?row.Archived?"Unarchive":"Archive":row.active!==true?"Unarchive":"Archive"}</div>:<></>}
                                <div className={styles.dropdownItem} onMouseDown={(event) => {event.stopPropagation(); onEdit(rowIndex);toggleDropdown(rowIndex)}}>Edit</div>
                                {user?.role==='admin'?<div className={styles.dropdownItem} onMouseDown={(event) =>{event.stopPropagation(); handleDelete(row._id);toggleDropdown(rowIndex)}}>Delete</div>:<></>}
                              </div>
                            )}  
                        </div>
                          
                      </div>
                      )
                    )}
                  </div>
                ))}
                
              </div>
              
            </React.Fragment>
          ))
            ):
              <div className='text-center mt-8'>
                <h1>No Data Found</h1>
              </div>
        }

        </div>
      </div>
    </div>
  );
};

export default Table;
