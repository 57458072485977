import React from 'react';
import { styled } from '@mui/material/styles';
import { InputBase, Box } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../images/Shared/Search.svg'; 

const SearchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #0000001A',
  borderRadius: '5px',
  padding: '4px, 6px, 4px, 6px', 
  backgroundColor: '#ffffff',
  width: '266px',
  height: '33px',
});

const StyledInputBase = styled(InputBase)({
  marginLeft: '8px',
  flex: 1,
  color: '#6F6B7D',
  fontWeight: 400,
  fontSize: 14,
  '& input::placeholder': {
    color: '#6F6B7D', 
    opacity: 1, 
    fontSize: 14,

  },
});

const SearchBar: React.FC<{searchTerm:string,onSearch:(value:string)=>void}> = ({searchTerm,onSearch}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };
  return (
    <SearchContainer>
      <SearchIcon style={{ marginLeft: '10px' }} /> 
      <StyledInputBase placeholder="Search" inputProps={{ 'aria-label': 'search' }} onChange={handleInputChange} value={searchTerm}/>
    </SearchContainer>
  );
};

export default SearchBar;
