// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { browserSessionPersistence, getAuth, setPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDRYAa6OdttOEcyuYwWq3Uo4nYF-Qq5EEA",
    authDomain: "mediamindtech-108bc.firebaseapp.com",
    projectId: "mediamindtech-108bc",
    storageBucket: "mediamindtech-108bc.appspot.com",
    messagingSenderId: "539101525080",
    appId: "1:539101525080:web:426d3fc68e81033f71062d",
    measurementId: "G-MMVPVP59HT"
  };
  
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const db = getFirestore(app);

export { app, auth, db };
