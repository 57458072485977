  import React, { useEffect, useMemo, useRef, useState } from 'react';
  import { Line } from 'react-chartjs-2';
  import { Chart, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
  import styles from './AccessCodeUsageSchool.module.css';
  import { ReactComponent as ArrowIcon } from '../../images/Reports/ArrowDownIcon.svg';
  import { ReactComponent as ArrowIconBold } from '../../images/Reports/ArrowBold.svg';
  import { ReactComponent as ArrowRightIcon } from '../../images/Reports/ArrowRightIcon.svg';
  import { ReactComponent as AddIcon } from '../../images/Reports/AddIcon.svg';
  import { ReactComponent as RemoveIcon } from '../../images/Reports/RemoveIcon.svg';
  import { ReactComponent as SmallArrow } from '../../images/Reports/SmallIcon.svg';
  import { ReactComponent as DetailsIcon } from '../../images/Reports/DetailsIcon.svg';
  import { ReportsService } from '../../services/ReportsService';
  import { SchoolsService } from '../../services/SchoolsService';
  import { SchoolResponseDTO, SchoolsDTO } from '../../dto/SchoolsDTO';
  import { BooksDTO, BooksResponseDTO } from '../../dto/BooksDTO';
  import { BooksService } from '../../services/BooksService';
  import { ChartOptions } from 'chart.js';
  import getYearsFrom2024 from '../../utils/dateUtils';
  import { CircularProgress } from '@mui/material';
import { showErrorToast } from '../Shared/Toastify/toastHelper';

  Chart.register(LineElement, PointElement, LinearScale, CategoryScale);

  interface SchoolDropdownDTO {
    _id:string;
    name: string;
    searchTerm:string;
    isOpen: boolean;
  }

  interface BookDropDownDTO{
    id: string;
    name: string;
    isOpen: boolean;
  }

  interface ChartData {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      fill: boolean;
    }[];
  }
interface ResponseItem {
  _id: { month: number };
  usedCodesCount: number;
}

  const transformToDropdownDTO = (schools: SchoolsDTO[]): SchoolDropdownDTO[] => {
    return schools.map(school => ({
      _id: school._id,  
      name: school.name,  
      searchTerm: "", 
      isOpen: false, 
    }));
  };

  const AccessCodeUsageSchool: React.FC = () => {
    const years = useMemo<string[]>(() => getYearsFrom2024(), []);

    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]); 
    const bookDropdownRef = useRef<HTMLDivElement|null>(null); 
    const yearDropdownRef = useRef<HTMLDivElement>(null); 

    const [showDropdown, setShowDropdown] = useState(false);

    const [allSchools,setAllSchools] = useState<SchoolDropdownDTO[]>([]);
    const [schoolDropdowns, setSchoolDropdowns] = useState<SchoolDropdownDTO[]>([{_id:"",name:"",searchTerm:'',isOpen:false}]);
    const [selectedSchoolIds, setSelectedSchoolIds] = useState<string[]>([]);
    const [filteredSchools, setFilteredSchools] = useState<SchoolDropdownDTO[]>([]);

    const [yearDropdown, setYearDropdown] = useState(false);
    const [selectedYear, setSelectedYear] = useState(years[0]||'2024');

    const [allBooks,setAllBooks]=useState<BooksDTO[]>([])
    const [bookDropdown, setBookDropdown] = useState(false);
    const [selectedBook, setSelectedBook] = useState({id:'',name:''});
    const [bookSearch, setBookSearch] = useState<string>('');
    const [filteredBooks, setFilteredBooks] = useState<BooksDTO[]>(allBooks);
    

    const [isArrowBold, setIsArrowBold] = useState(true);

    const [chartData, setChartData] = useState<ChartData>({
      labels: [],
      datasets: [],
    });

    const [loading,setLoading]=useState(false)
    const [dropdownLoading,setDropdownLoading]=useState(true)

    //close dropdown
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        dropdownRefs.current.forEach((ref, index) => {
          if (ref && !ref.contains(event.target as Node)) {
            setSchoolDropdowns(prevDropdowns =>
              prevDropdowns.map((school, i) =>
                i === index ? { ...school, isOpen: false } : school
              )
            );
          }
        });

        if (bookDropdownRef.current && !bookDropdownRef.current.contains(event.target as Node)) {
          setBookDropdown(false);
        }
        
        if (yearDropdownRef.current && !yearDropdownRef.current.contains(event.target as Node)) {
          setYearDropdown(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    //fetch schools and books
    useEffect(() => {
      const fetchData = async () => {
        try {
          
          
          const schoolResponse: SchoolResponseDTO = await SchoolsService.getSchools( false, 1, 10000, '');
          if (schoolResponse && Array.isArray(schoolResponse.schools)) {
            const mappedSchools: SchoolDropdownDTO[] = schoolResponse.schools.map(school => ({
              _id: school._id,  
              name: school.name,
              searchTerm: '',  
              isOpen: false   
            }));
            setAllSchools(mappedSchools);
          }
    
          const bookResponse: BooksResponseDTO = await BooksService.getBooks(10000, 1, false, true, "");
          if (bookResponse && Array.isArray(bookResponse.books)) {
            setAllBooks(bookResponse.books); 
            setFilteredBooks(bookResponse.books)
          }
        } catch (error) {
          showErrorToast('Error fetching schools.')
          console.error('Error while fetching schools', error);
        }finally{
          setDropdownLoading(false)
        }
      };
      fetchData();
    }, []);
    const colors = ['#00726B', '#BE1E2D', '#FFA500', '#800080'];
    
  // Fetch graph data
    useEffect(() => {
    const fetchData = async () => {
      try {

        setLoading(true)

        const responses = await Promise.all(selectedSchoolIds.map(schoolId =>
          ReportsService.getYearlyAccessCodePerSchool( schoolId, selectedBook.id, selectedYear)
        ));

        const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const datasets = responses.map((response, index) => {
          const data = Array(12).fill(0); // Initialize with zeros for all months.

          // Populate data with available counts from the response.
          response.result.forEach((item: ResponseItem) => {
            const monthIndex = item._id.month - 1; // Month is 1-based, so we subtract 1.
            data[monthIndex] = item.usedCodesCount;
          });

          return {
            label: schoolDropdowns[index]?.name || `School ${index + 1}`, // Handle missing names safely.
            data,
            borderColor: colors[index % colors.length], // Cycle colors if more datasets.
            fill: false,
          };
        });

        // Update the chart data state
        setChartData({
          labels: labels, // Using defined labels for the x-axis
          datasets: datasets,
        });

      } catch (error) {
        showErrorToast('Error fetching graph data.')

        console.error("Error while fetching the school access codes yearly", error);
      }finally{
        setLoading(false)
      }
    };

    if (selectedSchoolIds.length > 0 && selectedBook.id) {
      fetchData();
    } else {
      setChartData({
        labels: [], // Clear labels when no schools are selected
        datasets: [], // Clear datasets when no schools are selected
      });
    }
    }, [selectedSchoolIds, selectedBook, selectedYear]);

    const handleSearchChange = (index: number, value: string) => {
    
      const updatedDropdowns = schoolDropdowns.map((school, i) =>
        i === index ? { ...school, searchTerm: value,isOpen:true } : school
      );
      setSchoolDropdowns(updatedDropdowns);

    

      const filtered = filterSchools(value);
      setFilteredSchools(filtered);
    };

    const filterSchools = (searchTerm: string) => {
      return allSchools.filter((school) =>
        school.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    };

    const handleBookSearchChange = (value: string) => {
      setBookSearch(value);
    
      const filtered = allBooks.filter((book) =>
        book.bookTitle.toLowerCase().includes(value.toLowerCase())
      );

      setFilteredBooks(filtered);
    };
    
    const selectSchool = (schoolName: string, schoolId: string, index: number) => {
      const updatedDropdowns:SchoolDropdownDTO[] = schoolDropdowns.map((school, i) =>
        i === index ? { ...school, name: schoolName, _id: schoolId, isOpen: false,searchTerm:schoolName } : school
      );
      setSchoolDropdowns(updatedDropdowns);

      setSelectedSchoolIds((prevIds) => {
        const newIds = new Set(prevIds); 
        newIds.add(schoolId); 
        return Array.from(newIds); 
      });
      if(selectedBook.id.length > 0){
        setLoading(true)
      }
    };

    const options: ChartOptions<'line'> = {
      plugins: {
        tooltip: {
          enabled: true, // Ensure tooltips are enabled
          mode: 'index', // Tooltip mode
          intersect: false, // Tooltip should show on all items at that index
          callbacks: {
            label: (context) => {
              const label = context.dataset.label || '';
              const value = context.raw as number;
              return `${label}: ${value}`;
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
      },
      interaction: {
        mode: 'nearest', // Change to nearest to ensure tooltips show on hover
        intersect: false,
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: true,
            color: '#e0e0e0',
          },
          ticks: {
            stepSize: 1,
            callback: (value) => Number(value).toFixed(0),
          },
        },
      },
    };

    const addSchool = () => {
      const newSchool:SchoolDropdownDTO = { name: "please select a school",searchTerm:'' ,_id:"" ,isOpen: true };
      const updatedDropdowns: SchoolDropdownDTO[] = schoolDropdowns.map((school) => ({
        ...school,
        isOpen: false,
      }));
      updatedDropdowns.push(newSchool);
      setFilteredSchools(filterSchools(''))
      setSchoolDropdowns(updatedDropdowns);
    };

    const removeSchool = (index: number) => {
      const removedSchool = schoolDropdowns[index]; 
      const updatedDropdowns = schoolDropdowns.filter((_, i) => i !== index);
      setSchoolDropdowns(updatedDropdowns); 
      setSelectedSchoolIds((prevIds) => prevIds.filter(id => id !== removedSchool._id));
    };

    const toggleDropdown = (index: number) => {
      const updatedDropdowns:SchoolDropdownDTO[] = schoolDropdowns.map((school, i) => {
        if (i === index) {
          const isOpen = !school.isOpen;
          if (isOpen && school.searchTerm === "") { //show all options
          }
          return { ...school, isOpen,searchTerm:'' };
        } else {
          return { ...school, isOpen: false };
        }
      });
      setFilteredSchools(allSchools);
      setSchoolDropdowns(updatedDropdowns);
    };

    const handleHeaderClick = () => {
      setShowDropdown(!showDropdown);
      setIsArrowBold(!isArrowBold);
    };

    const handleYearClick = () => {
      setYearDropdown(!yearDropdown);
    };

    const selectYear = (year: string) => {
      setSelectedYear(year);
      setYearDropdown(false);
      setLoading(true)
    };
    
    const selectBook = (bookTitle: string, grades: string[], catName: string, id: string) => {
      console.log('book selected')
      const gradesString = grades.length > 0 ? grades.join(' - ') : ''; 
      const displayBook = `${bookTitle} | ${gradesString} | ${catName}`;
      setBookSearch(displayBook); 
      setSelectedBook({ id: id, name: displayBook });
      setBookDropdown(false); 
      if(selectedSchoolIds.length>0){
        setLoading(true)
      }
    };
    

    return (
      <div className={styles.container}>
        <div className={styles.header} onClick={handleHeaderClick}>
          Access Code Usage Per School
          <div className={styles.iconContainer}>
            {isArrowBold ? (
              <ArrowIconBold className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
            ) : (
              <ArrowRightIcon className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
            )}
          </div>
        </div>


        {showDropdown && (
          <>
          <hr className={styles.line} />
        <div className={styles.dropdownContainer}>
        {schoolDropdowns.map((school, index) => (
          <div key={index} ref={(el) => (dropdownRefs.current[index] = el)}>
            <div className={styles.icons}>
              <div className={styles.dropdownTitle}>{school.name||"Please select a school"}</div>
              {index === schoolDropdowns.length - 1 ? (
                <AddIcon className={styles.icon} onClick={() => addSchool()} />
              ) : null}
              {index > 0 && index === schoolDropdowns.length - 1 ? (
                <RemoveIcon className={styles.icon} onClick={() => removeSchool(index)} />
              ) : null}
            </div>
            <div className={styles.dropdown} >
              <div className={styles.dropdownHeader} onClick={() => toggleDropdown(index)}>
                <div className={styles.dropdownInnerContainer}>
                  <input type="text" onChange={(e)=>handleSearchChange(index,e.target.value)} value={school.searchTerm}  className={styles.inputSearch} placeholder='Search...'/>
                </div>
                <div>
                  <ArrowIcon className={styles.icon} />
                </div>
              </div>
              {school.isOpen && (
                <div className={styles.dropdownList}>
                  {!dropdownLoading?
                    filteredSchools.length>0?
                      filteredSchools.map((x, i) => (
                        <div key={i} className={styles.dropdownItem} onClick={() => selectSchool(x.name,x._id ,index)}>
                          {x.name}
                        </div>
                      ))
                    :<div className={styles.dropdownItem}>No data found</div>
                  :
                  <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
                    <CircularProgress/>
                  </div>
                }
                </div>
              )}
            </div>
          </div>
        ))}


        <div className={styles.dropdown}  ref={bookDropdownRef}>
            <div className={styles.dropdownTitle}>Book</div>
              <button className={styles.dropdownHeader} onClick={() => {setBookDropdown(!bookDropdown);handleBookSearchChange('')}} >
                <input 
                type="text" 
                value={bookSearch} 
                onChange={(e) => handleBookSearchChange(e.target.value)} 
                className={styles.inputSearch} 
                placeholder="Please select a book" 
              />
              <ArrowIcon className={`${styles.arrow} ${bookDropdown ? styles.arrowDown : ''}`} />
              </button>
            {bookDropdown&&
              <div className={styles.dropdownList}>
                  {!dropdownLoading?
                    filteredBooks.length>0?
                      filteredBooks.map((book, i) => {
                        const grades = book.grades?.map(grade => grade.engName) || []; 
                        return (
                            <div 
                                key={i} 
                                className={styles.dropdownItem} 
                                onClick={() => selectBook(book.bookTitle, grades, book.category?.engName ?? 'Unknown Category', book._id)}
                            >
                                {`${book.bookTitle} | ${grades.length > 0 ? grades.join(' - ') : ''} | ${book.category?.engName ?? ''}`}
                            </div>
                        );
                    })
                  :<div className={styles.dropdownItem}>No data found</div>
                :<div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
                  <CircularProgress/>
                </div>
              }
              </div>
            }
        </div>


        </div>
            <div className={styles.chartControls}>
              <div className={styles.accessCodeLabel}>Access codes used</div>
              <div className={styles.yearDropdown} ref={yearDropdownRef}>
                <div className={styles.yearHeader} onClick={handleYearClick}>
                  {selectedYear}
                  <SmallArrow className={`${styles.smallArrow} ${yearDropdown ? styles.arrowUp : ''}`} />
                </div>
                {yearDropdown && (
                  <div className={styles.yearList}>
                    {years.map((year, i) => (
                      <div key={i} className={styles.yearItem} onClick={() => selectYear(year)}>
                        {year}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.chartContainer}>
              {!loading?
                <Line data={chartData} options={{ ...options, maintainAspectRatio: false }} height={250} />
                :<CircularProgress/>
              }
            </div>
            <div className={styles.legend}>
              {schoolDropdowns.map((school, index) => (
                <div key={index} className={styles.legendItem}>
                  <div className={styles.colorCircle} style={{ backgroundColor: colors[index % colors.length] }} />
                  <span>{school.name}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  export default AccessCodeUsageSchool;