import React from 'react';
import styles from './ShortTextfield.module.css';

interface TextFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?:boolean
}

const ShortTextField: React.FC<TextFieldProps> = ({ label, value, onChange,disabled }) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={styles.textField}
        disabled={disabled}
      />
    </div>
  );
};

export default ShortTextField;
