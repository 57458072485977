import { getAuth } from "firebase/auth";
import { showErrorToast } from "../components/Shared/Toastify/toastHelper";

const auth = getAuth();
 

export async function FetchAuth(url: string, options: RequestInit = {}, retry = true): Promise<any> {
    try {
        let token = await auth.currentUser?.getIdToken()

  
        options.headers = {
            ...(options.headers instanceof Headers ? Object.fromEntries(options.headers.entries()) : options.headers),
            "Authorization": `${token}`,
        } as Record<string, string>;

        const response = await fetch(url, options);


        if (!response.ok) {
            

            throw new Error('Error occured.');
        }

        return response;
    } catch (error) {
        console.error("Fetch request error:", error);
        throw error;  
    }
}

