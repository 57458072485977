import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ReactComponent as RefreshIcon } from '../../images/Shared/Refresh.svg';
import styles from './UserForm.module.css';
import Dropdown from '../../components/Shared/Textfields/FillerDropdown';
import AddButton2 from '../../components/Shared/AddButton/AddButton2';
import Table from '../../components/Shared/Table/UserFormTable'; 
import { ArchiveResponse, CreateUserDTO, MyLibraryDTO, UpdateUserDTO, UserDTO } from '../../dto/UserDTO';
import { UserService } from '../../services/UserService';
import BookAddModal from './BookAddModal';
import { CircularProgress } from '@mui/material';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { UserContext } from '../../UserContext';

interface UserFormProps {
  mode: 'add' | 'edit' | 'view';
}

interface UserData {
  _id:string;
  Name: string;
  Email: string;
  Role: string;
  Password: string;
  maxDevices: number;
  Active:boolean
}
interface FlattenedLibraryData {
  _id: string;
  accessCode: string;
  bookTitle: string;
  batchTitle: string;
  activationDate: string;
  expirationDate: string;
  bookCover:string
} 

interface Column {
  title: string;
  key: keyof FlattenedLibraryData; 
}

interface EmailAvailabityResponseDTO{
  message:string;
  available:boolean;
}

const RoleOptions = ['Teacher', 'Student','Other'];

const columns:Column[] = [
  { title: 'ID', key: '_id' },  
  { title: 'Cover', key: 'bookCover' },
  { title: 'Book Title', key: 'bookTitle' },
  { title: 'Batch Title', key: 'batchTitle' },
  { title: 'Access Code', key: 'accessCode' },
  { title: 'Activation Date', key: 'activationDate' },
  { title: 'Expiration Date', key: 'expirationDate' },
];


const UserForm: React.FC<UserFormProps> = ({ mode }) => {
  const {user}=useContext(UserContext)
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData>({
    _id:'',
    Name: '',
    Email: '',
    Role: '',
    Password: '',
    maxDevices: 0,
    Active:false,
  });
  const [library,setLibrary]=useState<MyLibraryDTO[]|[]>([])
  const [selectedRole,setSelectedRole]=useState<string>('')

  const [showModal,setShowModal]=useState(false);

  const [loading,setLoading]=useState(true)
  const [buttonLoading,setButtonLoading]=useState(false)

  const [showArchiveModal,setShowArchiveModal]=useState(false)
  const [modalMode,setModalMode]=useState<'archive'|'delete'>('archive')
  
  const formatDate = (dateString: string | undefined) => {
  if (!dateString) return 'null'; // Handle missing date gracefully
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
};

  //fetch user
  useEffect(() => {
    const fetchUser = async () => {
      try {
        
        if (id ) {
          const fetchedUserData: UserDTO = await UserService.getUserAdmin( id)
          setUserData({
            _id:fetchedUserData._id,
            Name: fetchedUserData.fullname,
            Email: fetchedUserData.email,
            Role: fetchedUserData.role,
            Password: "",
            maxDevices: fetchedUserData.maxDevices,
            Active:fetchedUserData.active
          });
          const formattedLibrary = fetchedUserData.myLibrary.map((book) => ({
            ...book,
            activationDate: formatDate(book.activationDate),
            expirationDate: formatDate(book.expirationDate),
          }));
          console.log(formattedLibrary)
          setLibrary(formattedLibrary||[])
          setSelectedRole(fetchedUserData.role);
        } else{
          showErrorToast('Error user id not found.')
        }
      } catch (error) {
        showErrorToast('Error fetching user.')
        console.error('Error fetching user:', error);
      }finally{
        setLoading(false)
      }
    };
    if(mode!=='add'){
      fetchUser();
    }else{
      setLoading(false)
    }
  }, [mode, id]);


  const handleSelectedRole=(value:string)=>{
    setSelectedRole(value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.valueAsNumber < 0) {
      e.target.value = '0';
    }
    setUserData({ ...userData, maxDevices: parseInt(e.target.value, 10) });
  };

  const validateForm=()=>{
    if(!userData.Email?.trim()){
      console.log('email missing')
      return false
    }
    if(!userData.Name?.trim()){
      console.log('name missing')
      return false
    }
    if(mode ==='add'){
      if(!userData.Password?.trim() && userData.Password.trim().length<6){
      console.log('password missing')

        return false
      }
    }
    if(!selectedRole?.trim()){
      console.log('role missing')

      return false
    }
    if(!userData?.maxDevices){
      console.log('device count missing')

      return false
    }
    return true
  }

  const handleSubmit =async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!validateForm()){
      showErrorToast("All fields are required")
      return 
    }
    if (mode === 'add') {
      await handleAddUser()
      navigate('/users');
    } else if (mode === 'edit') {
      await handleEditUser()
      navigate(`/users/view/${id}`);
    }
  };

  const  handleEditUser=async()=>{
    try {
      setButtonLoading(true)
 
      if(!id){
        showErrorToast('Error user id not found.')

        return 
      }

      const newUser:UpdateUserDTO={
        fullname:userData.Name,
        email:userData.Email,
        maxDevices:userData.maxDevices,
        role:selectedRole.toLowerCase()
      }
      console.log(newUser)
     await UserService.updateUser(id,newUser)
 
      showSuccessToast("User updated successfully")
    } catch (error) {
      showErrorToast("Error updating user.")
      console.error("error while adding a user :",error)

    }finally{
      setButtonLoading(false)
    }
  }

  const handleAddUser=async()=>{
    try {
      setButtonLoading(true)

      const newUser:CreateUserDTO={
        fullname:userData.Name,
        email:userData.Email,
        password:userData.Password,
        role:selectedRole.toLowerCase(),
        maxDevices:userData.maxDevices
      }
     await UserService.createUser(newUser)
 
      showSuccessToast("User created successfully")
    } catch (error) {
      showErrorToast("Error adding user.")

      console.error("error while adding a user :",error)

    }finally{
      setButtonLoading(false)
    }
  }

  const  handleResetPassword=async()=>{
    const email= userData.Email
    if(!email){
      showErrorToast('Email is required.')
      return 
    }
    try{
      const auth=getAuth();
      const response =await sendPasswordResetEmail(auth,email)
      showSuccessToast('Email sent.')
    }catch(error){
      console.error('error while sending reset password email',error)
      showErrorToast('error while sending reset password email'+error)
    }

  }

  const handleArchive=()=>{
    setModalMode('archive')
    setShowArchiveModal(true)
  }

  const handleDelete=()=>{
    setModalMode('delete')
    setShowArchiveModal(true)
  }

  const handleConfirm=async()=>{
    try {

      if(!userData._id){
        showErrorToast('Id is required')
      }
      if(modalMode==='archive'){
        const response:ArchiveResponse=await UserService.toggleActive(userData._id)
        showSuccessToast(response.message)
      }else if(modalMode === 'delete'){
        
        const response:ArchiveResponse=await UserService.deleteUser(userData._id)
        showSuccessToast("User delete!")
      }
      navigate('/users')
    } catch (error) {
      if(modalMode==='archive'){
        showErrorToast('Error toggling archive user.')
      }else if(modalMode === 'delete'){
        showErrorToast('Error deleting user.')

      }
      console.error("error while archiving or deleting",error)
    }
  }


  const handlePasswordBlur=()=>{
    if(userData.Password.length<6){
      showErrorToast('Password must be longer than 6 characters.')
    }
  }

  if(loading){
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
        <CircularProgress/>
      </div>
    )
  }

  return (
    <div className={styles.background}>
      <div className={styles.Title}>User Information</div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.row}>
          <div className={styles.field}>
            <label>Name:</label>
            <input
              type="text"
              value={userData.Name}
              onChange={(e) => setUserData({ ...userData, Name: e.target.value })}
              className={styles.textField}
            />
          </div>
          <div className={styles.field}>
            <label>Email Address:</label>
            <input
              type="email"
              value={userData.Email}
              onChange={(e) => setUserData({ ...userData, Email: e.target.value })}
              className={styles.textField}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <Dropdown label="Role" options={RoleOptions} selectedOption={selectedRole} onSelect={handleSelectedRole} disabled={mode==="view"} />
          </div>
          <div className={styles.field}>
            <label>Device Count:</label>
            <div className={styles.inputContainer}>
              <input
                type="number"
                value={userData.maxDevices}
                onInput={handleInputChange}
                className={styles.DevicetextField}
                min="0"
              />
              <RefreshIcon 
                className={styles.refreshIcon} 
                onClick={() => setUserData({ ...userData, maxDevices: 0 })} 
                style={{ cursor: 'pointer', marginLeft: '12px' }} 
              />
            </div>
          </div>
        </div>
        {mode==='add'?<div className={styles.row}>
          <div className={styles.field}>
            <label>Password:</label>
            <input
              type="text"
              value={userData.Password}
              onChange={(e) => setUserData({ ...userData, Password: e.target.value })}
              className={styles.textField}
              onBlur={handlePasswordBlur}
            />
          </div>
        </div>:<></>}
     {mode!=='add'? 
      <div>
        <div className={styles.batchesContainer}>

          <div className={styles.Title}>User Books</div>

          <button className={styles.link} onClick={()=>setShowModal(true)} type="button">
            <AddButton2 label="ADD BOOK" />
          </button>

        </div>

          <Table columns={columns} data={library} userId={id}/>
           
      </div>
      :<></>}



        {mode !== 'view' && (
          <>
              <div className={styles.buttonContainer}>
                {mode!=="add" &&
                <div style={{display:'flex',flexDirection:'column',gap:'25px'}}>
                  <div style={{fontWeight:600,fontSize:'20px'}}>Reset Password</div>
                  <button className={styles.ResetEmailButton} type="button" onClick={handleResetPassword}>
                    SEND RESET EMAIL
                  </button>
                  </div>
                }

                <div className={styles.spacer}></div>
                <div style={{display:'flex',gap:'32px'}}>
                  {mode!=='add' && user?.role==="admin"?
                    <>  
                      <button className={styles.DeleteButton} type="button" onClick={handleDelete}>
                        DELETE
                      </button>
                      <button className={styles.ArchiveButton} type="button" onClick={handleArchive}>
                        {userData.Active===true?"ARCHIVE":'UNARCHIVE'}
                      </button>
                    </>
                  :<></>}
                  <button className={styles.AddButton} type="submit">
                    {buttonLoading?<CircularProgress style={{color:'white'}}/>:mode === 'add' ? 'ADD' : 'UPDATE'}
                  </button>
                </div>
              </div>
          </>
        )}


        {showModal &&
          <BookAddModal setShowModal={setShowModal} userId={id!}/>}
      </form>
      <ArchiveModal
            setIsOpen={setShowArchiveModal}

        isOpen={showArchiveModal}
        onConfirm={handleConfirm}
        onCancel={() => setShowArchiveModal(false)}
        header={modalMode === 'archive' ?`${userData?.Active===true?'Archive':'Unarchive'} User` : modalMode==='delete'?  'Delete User':''}
        parag={modalMode === 'archive' ? `Are you sure you want to ${userData?.Active===true?'archive':'Unarchive'} this user?` : 'Are you sure you want to delete this user?'}
        buttonText={modalMode === 'archive' ? userData?.Active===true?'Yes, Archive':'Yes, Unarchive'   : 'Delete'}
        mode={modalMode}
      />
    </div>
  );
}

export default UserForm;
