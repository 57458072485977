import React, { SetStateAction } from "react";
import { UserDTO } from "./dto/UserDTO";

interface IUserContext {
    user: UserDTO | null;
    setUser: React.Dispatch<React.SetStateAction<UserDTO | null>>;
    // token: null | string;
    // setToken: React.Dispatch<React.SetStateAction<string | null>>;
    rememberMe:boolean;
    setRememberMe:React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState: IUserContext = {
    user: null,
    setUser: () => {},
    // token: null,
    // setToken: () => {},
    rememberMe:false,
    setRememberMe:()=>{},
};

export const UserContext = React.createContext<IUserContext>(defaultState);
