import React, { useState, useEffect } from 'react';
import AddButton from '../../components/Shared/AddButton/AddButton';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import { Link, useNavigate } from 'react-router-dom';
import { CategoriesDTO, CategoriesResponseDTO } from '../../dto/CategoriesDTO';
import Table from '../../components/Shared/Table/CategTable';
import styles from './Categories.module.css';
import { CategoriesService } from '../../services/CategoriesService'; 
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';
import { SelectChangeEvent } from '@mui/material';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';


const Categories: React.FC = () => {
    const columns = [
        { title: 'Default Icon' },
        { title: 'Active Icon' },
        { title: 'Name - EN' },
        { title: 'Name - FR' },
        { title: 'Name - AR' },
        { title: 'Total Books' },
        { title: 'Created Date' },
    ];

    const [categories, setCategories]=useState<CategoriesDTO[]>([]);
    const [total,setTotal]=useState(0);
    const [currentPage, setCurrentPage] = useState(1); 
    const [activeButton, setActiveButton] = useState(0);  
    const [pageSize, setPageSize] = useState(10);
    const [loading,setLoading]=useState<boolean>(true);
    const [searchTerm,setSearchTerm] = useState<string>('');

    const [showModal, setShowModal] = useState(false);
    const [mode, setMode] = useState<'activate' | 'delete'>('activate'); 
    
    const [selectedCategoryId,setSelectedCategoryId]=useState<string|null>(null)

    const handleConfirmAction = async (): Promise<void> => {
        
        if (selectedCategoryId) {
            
            try {
                if (mode === 'activate') {
                    const message=await CategoriesService.toggleActivate( selectedCategoryId);
                    showSuccessToast(message)
                } else if (mode === 'delete') {
                    const response= await CategoriesService.deleteCategory( selectedCategoryId);
                    showSuccessToast(response)
                }
                setCategories(categories.filter(user => user._id !== selectedCategoryId));
                setSelectedCategoryId(null);
            } catch (error) {
                showErrorToast(`Error ${mode === 'activate' ? 'toggling archive' : 'deleting'} category`)
                console.error(`Error ${mode === 'activate' ? 'toggling archive' : 'deleting'} category`);
            }
            
        }
        setShowModal(false);
    };

    const handleCancelAction = (): void => {
        setSelectedCategoryId(null);
        setShowModal(false);
    };

    const handleArchiveClick = (id: string): void => {
        setSelectedCategoryId(id);
        setMode('activate'); 
        setShowModal(true);
    };

    const handleDeleteClick = (id:string): void => {
        setSelectedCategoryId(id);
        setMode('delete');
        setShowModal(true);
    };

    const handleSearchChange = (value: string) => {
        setLoading(true)
        setCurrentPage(1)
        setSearchTerm(value); 
    };

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setCurrentPage(1)
        setPageSize(Number(event.target.value));
    };

    const handlePageChange = (page: number) => {
        setLoading(true)
        setCurrentPage(page);
    };

    const handleActiveButton=(index:number)=>{
        setLoading(true)
        setCurrentPage(1)

        setActiveButton(index)
    }   

    const navigate = useNavigate();


    const handleEditClick = (index: number) => {
        const category = categories[index];
        if (category) {
            navigate(`/categories/edit/${category._id}`);
        }
    };

    const handleViewClick = (index: number): void => {
        const category = categories[index];
        if (category) {
            navigate(`/categories/view/${category._id}`);
        }
    };

    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }


    const   data = categories.map((category: CategoriesDTO) => ({
        "_id":category._id,
        'Default Icon': category.inactiveIcon,
        'Active Icon': category.activeIcon,
        'Name - EN': category.engName,
        'Name - FR': category.frName,
        'Name - AR': category.arName,
        'Total Books' :category.totalBooks,
        'Created Date': formatDate(category.dateCreated),
        'Archived':category.archived
    }));
    
    const label =[
        {
            label_name: "Active",
            index: 0
        },
        {
            label_name: "Deactivated",
            index: 1
        }
    ]

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                
                const archived=activeButton===0?false:true
                const response:CategoriesResponseDTO = await CategoriesService.getCategories(currentPage,pageSize,archived,searchTerm);
                if (response && response.categories && Array.isArray(response.categories)) {
                    setCategories(response.categories);
                    setTotal(response.total)
                } else {
                    console.error('Invalid response format:', response);
                }
            
            } catch (error) {
                showErrorToast('Error fetching categories.')
                console.error('Failed to fetch categories:', error);
            }finally{
                setLoading(false);
            }
        };

        fetchCategories();
    }, [currentPage,pageSize,activeButton,searchTerm]);


    return (

        <div className={styles.container}>
            <div className={styles.content}>
                <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleActiveButton} />

                <div className='flex'>
                    <Link to="/categories/add" className={styles.link}>
                        <AddButton label="ADD CATEGORY" />
                    </Link>
                    
                    <ExportButton label="Export" exportType='categories'/> 
                </div>
            </div>
            <div className={styles.content2}>
                <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
                <PaginationInfo totalItems={total} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}/>
            
            </div>
            <Table
                columns={columns}
                data={data}
                onEdit={handleEditClick}
                onView={handleViewClick}
                onDelete={handleDeleteClick}
                onArchive={handleArchiveClick}
                loading={loading}
            />
            <div className={styles.content3}>
                <Pagination3 totalPages={Math.ceil(total / pageSize)} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
            <ArchiveModal
            setIsOpen={setShowModal}

                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode === 'activate' ? activeButton===0?'Archive':"Unarchive" : 'Delete'} this category?`}
                parag={`Sure you want to ${mode === 'activate' ? activeButton===0?'Archive':"Unarchive" : 'Delete'} this category?`}
                mode={mode} 
                buttonText={mode==='activate' ? activeButton===0?'Yes, archive':"Yes, unarchive" : 'Yes, delete'}

            />
        </div>
    );
}

export default Categories;
