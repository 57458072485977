import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../../images/Shared/Arrow2.svg'; 
import styles from './Dropdown.module.css';

interface DropdownProps {
  label: string;
  options: string[];
  selectedOption: string;
  onSelect: (value: string) => void;
  disabled?: boolean;  
}

const Dropdown: React.FC<DropdownProps> = ({ label, options, selectedOption, onSelect, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm,setSearchTerm]=useState(selectedOption);
  const dropdownRef=useRef<HTMLDivElement|null>(null)
  
  useEffect(() => {
    setSearchTerm(selectedOption);
  }, [selectedOption]);

  
  useEffect(() => {
     const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    if (disabled) return;  
    setSearchTerm('')
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    if (disabled) return;
    setSearchTerm(option);  
    onSelect(option);
    setIsOpen(false);
  };  

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true)
  };

  const filteredOptions = options.filter(option => 
    option?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
  );

  return (
    <div ref={dropdownRef} className={`${styles.dropdownContainer} ${disabled ? styles.disabled : ''}`} >
      <label className={styles.label}>{label}</label>
      <div className={`${styles.dropdown} ${disabled ? styles.disabledDropdown : ''}`} onClick={toggleDropdown}>
      <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          onClick={toggleDropdown}
          className={`${styles.searchInput} ${disabled ? styles.disabledDropdown : ''}`}
          placeholder="Search..."
          disabled={disabled}
        />  
        <ArrowIcon className={styles.arrowIcon} />
        {isOpen && (
          <ul className={styles.options}>
            {filteredOptions.length>0?
            filteredOptions.map((option, index) => (
              <li key={index} onClick={() => handleOptionClick(option)} className={styles.option}>
                {option}
              </li>
            )):<p className={styles.option}>No data found</p>
          }
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
